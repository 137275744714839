import React from 'react';
import { Grid } from '@mui/material';
import ExploitationsWidget from '../features/exploitations/ExploitationsWidget';

const ExploitationsPage = () => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{
        maxWidth: 1200,
        mx: 'auto',
        px: 3,
        width: '100%'
      }}>
        <ExploitationsWidget />
      </Grid>
    </Grid>
  );
};

export default ExploitationsPage;