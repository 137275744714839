import TileLayer from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import OSM from 'ol/source/OSM'
import TileWMS from 'ol/source/TileWMS'

export const createBaseLayers = () => ({
  ign: new TileLayer({
    source: new TileWMS({
      url: 'https://www.ign.es/wms-inspire/mapa-raster',
      params: {
        LAYERS: 'mtn_rasterizado',
        TILED: true,
        FORMAT: 'image/png',
        VERSION: '1.3.0',
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous',
    }),
    visible: true,
  }),
  esri: new TileLayer({
    source: new XYZ({
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      attributions: 'Tiles © Esri',
      crossOrigin: 'anonymous',
    }),
    visible: false,
  }),
  sigpac: new TileLayer({
    source: new TileWMS({
      url: 'https://wms.mapa.gob.es/sigpac/wms',
      params: {
        LAYERS: 'AU.Sigpac:recinto',
        TILED: true,
        FORMAT: 'image/png',
        TRANSPARENT: true,
        VERSION: '1.3.0',
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous',
    }),
    opacity: 0.7,
    visible: false,
  }),
  osm: new TileLayer({
    source: new OSM(),
    visible: false,
  }),
})
