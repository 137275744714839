import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://terrasense.servebeer.com:8090/api';

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true // Asegura que las cookies se envíen con las solicitudes
});

// Sistema mejorado de gestión de CSRF
let csrfToken = null;
let csrfPromise = null;

/**
 * Obtiene un nuevo token CSRF del servidor
 */
const obtainCsrfToken = async () => {
  try {
    // Limpiar cookie anterior (mejor compatibilidad entre dominios)
    document.cookie = 'XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    const cookie = document.cookie
      .split('; ')
      .find(row => row.trim().startsWith('XSRF-TOKEN='));

    csrfToken = cookie.split('=')[1];
    return csrfToken;
  } catch (error) {
    throw new Error('Error de seguridad. Intenta recargar la página.');
  } finally {
    csrfPromise = null;
  }
};

/**
 * Interceptor para añadir headers necesarios
 */
apiClient.interceptors.request.use(async (config) => {
  // Agregar token de autenticación si existe
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // Simplificar manejo de CSRF solo para métodos que lo necesitan
  if (['post', 'put', 'patch', 'delete'].includes(config.method?.toLowerCase())) {
    try {
      if (!csrfToken && !csrfPromise) {
        csrfPromise = obtainCsrfToken().finally(() => { csrfPromise = null; });
        csrfToken = await csrfPromise;
      }
      config.headers['X-XSRF-TOKEN'] = csrfToken;
    } catch (error) {
      // No bloquear la solicitud en desarrollo
      if (process.env.NODE_ENV === 'production') {
        throw error;
      }
    }
  }

  return config;
}, error => {
  return Promise.reject(error);
});

/**
 * Interceptor para manejar respuestas
 */
apiClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Manejar errores CSRF
    if (error.response?.status === 403 &&
        error.response.data?.error?.toLowerCase().includes('csrf') &&
        !originalRequest._retry) {

      try {
        originalRequest._retry = true;
        csrfToken = null;
        csrfPromise = null;

        await obtainCsrfToken();
        return apiClient(originalRequest);
      } catch (e) {
        return Promise.reject(new Error('Problema de seguridad persistente. Recarga la aplicación.'));
      }
    }

    // Manejar token expirado (opcional)
    if (error.response?.status === 401 &&
        !originalRequest._retry &&
        originalRequest.url !== '/auth/generate') {
      // Aquí podrías añadir lógica para refrescar el token
    }

    return Promise.reject(error);
  }
);

export default apiClient;