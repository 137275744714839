import apiClient from './apiClient' // Importamos el cliente configurado

const ExploitationAPI = {
  /**
   * Obtiene todas las explotaciones
   * @returns {Promise<Array>} Lista de explotaciones
   */
  async fetchAllExploitations() {
    try {
      const response = await apiClient.get('/exploitations/all')
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  },

  /**
   * Obtiene una explotación por su ID
   * @param {number} id
   * @returns {Promise<Object>} Datos de la explotación
   */
  async fetchExploitationById(id) {
    try {
      const response = await apiClient.get(`/exploitations/${id}`)
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  },

  /**
   * Crea una nueva explotación
   * @param {Object} exploitationData
   * @returns {Promise<Object>} Explotación creada
   */
  async createExploitation(exploitationData) {
    try {
      const response = await apiClient.post('/exploitations', exploitationData)
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  },

  /**
   * Actualiza una explotación existente
   * @param {number} id
   * @param {Object} exploitationData
   * @returns {Promise<Object>} Explotación actualizada
   */
  async updateExploitation(id, exploitationData) {
    try {
      const response = await apiClient.put(
        `/exploitations/${id}`,
        exploitationData,
      )
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  },

  /**
   * Elimina una explotación
   * @param {number} id
   * @returns {Promise<boolean>} True si se eliminó correctamente
   */
  async deleteExploitation(id) {
    try {
      await apiClient.delete(`/exploitations/${id}`)
      return true
    } catch (error) {
      throw this.handleError(error)
    }
  },

  /**
   * Obtiene las parcelas asociadas a una explotación
   * @param {number} exploitationId
   * @returns {Promise<Array>} Lista de parcelas
   */
  async getExploitationParcels(exploitationId) {
    try {
      const response = await apiClient.get(
        `/exploitations/${exploitationId}/parcels`,
      )
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  },

  /**
   * Obtiene los cultivos asociados a una explotación
   * @param {number} exploitationId
   * @returns {Promise<Array>} Lista de cultivos
   */
  async getExploitationCultivations(exploitationId) {
    try {
      const response = await apiClient.get(
        `/exploitations/${exploitationId}/cultivations`,
      )
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  },

  /**
   * Sube una imagen/documento asociado a la explotación
   * @param {number} exploitationId
   * @param {File} file
   * @param {string} type Tipo de documento (image, contract, etc.)
   * @returns {Promise<Object>} Respuesta del servidor
   */
  async uploadExploitationDocument(exploitationId, file, type = 'image') {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('type', type)

      const response = await apiClient.post(
        `/exploitations/${exploitationId}/documents`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  },

  /**
   * Obtiene la URL de un documento de la explotación
   * @param {number} exploitationId
   * @param {string} documentId
   * @returns {string} URL completa del documento
   */
  getExploitationDocumentUrl(exploitationId, documentId) {
    return `${apiClient.defaults.baseURL}/exploitations/${exploitationId}/documents/${documentId}`
  },

  /**
   * Manejo centralizado de errores
   * @param {Error} error
   * @returns {Error} Error formateado con detalles
   */
  handleError(error) {
    let errorMessage = 'Error al procesar la solicitud de explotación'
    let errorDetails = null

    if (error.response) {
      errorMessage =
        error.response.data?.message ||
        error.response.data?.error ||
        `Error ${error.response.status}: ${error.response.statusText}`

      // Para errores de validación
      if (error.response.data?.errors) {
        errorDetails = error.response.data.errors
      }
    } else if (error.request) {
      errorMessage = 'El servidor no respondió'
    } else if (error.message) {
      errorMessage = error.message
    }

    const formattedError = new Error(errorMessage)
    formattedError.details = errorDetails
    formattedError.status = error.response?.status

    return formattedError
  },
}

export default ExploitationAPI
