import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  Box,
  Avatar,
  Button,
  Stack,
  Tooltip
} from '@mui/material';
import {
  Menu as MenuIcon,
  Warehouse as WarehouseIcon,
  Map as MapIcon,
  Agriculture as AgricultureIcon,
  Build as BuildIcon,
  Grass as GrassIcon,
  TaskAlt as TaskAltIcon,
  SettingsRemote as SettingsRemoteIcon,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import logo from '../assets/logo_s.png';
import '../css/SidebarMenu.css';

const menuItems = [
  { text: 'Explotaciones', icon: <WarehouseIcon />, path: '/explotations' },
  { text: 'Parcelas', icon: <MapIcon />, path: '/parcels' },
  { text: 'Vehículos', icon: <AgricultureIcon />, path: '/vehicles' },
  { text: 'Herramientas', icon: <BuildIcon />, path: '/tools' }
];

const secondaryItems = [
  { text: 'Cultivos', icon: <GrassIcon />, path: '/cultivations' },
  { text: 'Tareas', icon: <TaskAltIcon />, path: '/tasks' },
  { text: 'Dispositivos', icon: <SettingsRemoteIcon />, path: '/devices' }
];

export const SidebarMenu = ({ isOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const { handleLogout } = useAuth();

  const handleLogoutClick = async () => {
    try {
      await handleLogout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      // Redirigir incluso si hay error para limpiar el estado
      navigate('/login', { replace: true });
    }
  };

  const sesionItems = [
    { text: 'Usuario', icon: <PersonIcon />, path: '/profile' },
    {
      text: 'Cerrar sesión',
      icon: <ExitToAppIcon />,
      onClick: handleLogoutClick
    }
  ];

  return (
    <div className={`sidebar-container ${isOpen ? 'expanded' : 'collapsed'}`}>
      {/* Logo y título */}
      <Box className="logo-container">
        <Avatar
          src={logo}
          variant="square"
          className="logo-avatar"
          sx={{
            width: isOpen ? 120 : 40,
            height: 40,
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
          }}
        />
        {isOpen && (
          <Typography variant="h6" className="logo-text" component="div">
            AgroAdmin
          </Typography>
        )}
      </Box>

      {/* Menú completo */}
      <Stack className="menu-stack" spacing={1}>
        {/* Botón de menú */}
        <Tooltip title="Menú" placement="right" arrow disableHoverListener={isOpen}>
          <Button
            className="menu-button"
            onClick={toggleDrawer}
            startIcon={<MenuIcon className="menu-icon" />}
          >
            {isOpen && <span className="menu-text">Menú</span>}
          </Button>
        </Tooltip>

        <Divider className="menu-divider" />

        {/* Elementos principales */}
        {menuItems.map(({ text, icon, path }) => (
          <Tooltip key={text} title={text} placement="right" arrow disableHoverListener={isOpen}>
            <Button
              component={Link}
              to={path}
              className="menu-button"
              startIcon={React.cloneElement(icon, { className: 'menu-icon' })}
            >
              {isOpen && <span className="menu-text">{text}</span>}
            </Button>
          </Tooltip>
        ))}

        <Divider className="menu-divider" />

        {/* Elementos secundarios */}
        {secondaryItems.map(({ text, icon, path }) => (
          <Tooltip key={text} title={text} placement="right" arrow disableHoverListener={isOpen}>
            <Button
              component={Link}
              to={path}
              className="menu-button"
              startIcon={React.cloneElement(icon, { className: 'menu-icon' })}
            >
              {isOpen && <span className="menu-text">{text}</span>}
            </Button>
          </Tooltip>
        ))}

        <Divider className="menu-divider" />

        {/* Elementos de sesión */}
        {sesionItems.map(({ text, icon, path, onClick }) => (
          <Tooltip key={text} title={text} placement="right" arrow disableHoverListener={isOpen}>
            <Button
              className="menu-button"
              startIcon={React.cloneElement(icon, { className: 'menu-icon' })}
              {...(path ? { component: Link, to: path } : { onClick })}
            >
              {isOpen && <span className="menu-text">{text}</span>}
            </Button>
          </Tooltip>
        ))}
      </Stack>
    </div>
  );
};

SidebarMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired
};

export default SidebarMenu;