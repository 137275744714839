// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* components/SectionsWall.css */
.sections-wall-container {
  width: 100%;
  padding: 24px;
}

.sections-wall-title {
  margin-bottom: 32px !important;
  text-align: center;
}

.sections-wall-grid-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sections-wall-grid {
  width: 100%;
  max-width: 1200px; /* Ajusta según necesites */
}

.sections-wall-card {
  height: 100%;
  display: flex;
  min-height: 180px; /* Altura fija */
  transition: all 0.3s ease;
}

.sections-wall-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 20px;
  text-align: center;
  flex-grow: 1;
}

.sections-wall-icon {
  font-size: 3rem;
  transition: all 0.3s ease;
}

/* Usa clases de color del tema MUI */
.sections-wall-icon.primary {
  color: var(--mui-palette-primary-main) !important;
}

.sections-wall-icon.secondary {
  color: var(--mui-palette-secondary-main) !important;
}

.sections-wall-card-title {
  font-weight: 600;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/SectionsWall.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,iBAAiB,EAAE,2BAA2B;AAChD;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB,EAAE,gBAAgB;EACnC,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA,qCAAqC;AACrC;EACE,iDAAiD;AACnD;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":["/* components/SectionsWall.css */\r\n.sections-wall-container {\r\n  width: 100%;\r\n  padding: 24px;\r\n}\r\n\r\n.sections-wall-title {\r\n  margin-bottom: 32px !important;\r\n  text-align: center;\r\n}\r\n\r\n.sections-wall-grid-container {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.sections-wall-grid {\r\n  width: 100%;\r\n  max-width: 1200px; /* Ajusta según necesites */\r\n}\r\n\r\n.sections-wall-card {\r\n  height: 100%;\r\n  display: flex;\r\n  min-height: 180px; /* Altura fija */\r\n  transition: all 0.3s ease;\r\n}\r\n\r\n.sections-wall-card-content {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  width: 300px;\r\n  padding: 20px;\r\n  text-align: center;\r\n  flex-grow: 1;\r\n}\r\n\r\n.sections-wall-icon {\r\n  font-size: 3rem;\r\n  transition: all 0.3s ease;\r\n}\r\n\r\n/* Usa clases de color del tema MUI */\r\n.sections-wall-icon.primary {\r\n  color: var(--mui-palette-primary-main) !important;\r\n}\r\n\r\n.sections-wall-icon.secondary {\r\n  color: var(--mui-palette-secondary-main) !important;\r\n}\r\n\r\n.sections-wall-card-title {\r\n  font-weight: 600;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
