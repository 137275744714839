import apiClient from './apiClient'; // Importamos el cliente configurado

const ParcelAPI = {
  /**
   * Obtiene todas las parcelas
   * @returns {Promise<Array>} Lista de parcelas
   */
  async fetchAllParcels() {
    try {
      const response = await apiClient.get('/parcels/all');
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Obtiene una parcela por su ID
   * @param {number} id
   * @returns {Promise<Object>} Datos de la parcela
   */
  async fetchParcelById(id) {
    try {
      const response = await apiClient.get(`/parcels/${id}`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Crea una nueva parcela
   * @param {Object} parcelData
   * @returns {Promise<Object>} Parcela creada
   */
  async createParcel(parcelData) {
    try {
      const response = await apiClient.post('/parcels', parcelData);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Actualiza una parcela existente
   * @param {number} id
   * @param {Object} parcelData
   * @returns {Promise<Object>} Parcela actualizada
   */
  async updateParcel(id, parcelData) {
    try {
      const response = await apiClient.put(`/parcels/${id}`, parcelData);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Elimina una parcela
   * @param {number} id
   * @returns {Promise<boolean>} True si se eliminó correctamente
   */
  async deleteParcel(id) {
    try {
      await apiClient.delete(`/parcels/${id}`);
      return true;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Obtiene la explotación asociada a una parcela
   * @param {number} parcelId
   * @returns {Promise<Object>} Datos de la explotación
   */
  async getParcelExploitation(parcelId) {
    try {
      const response = await apiClient.get(`/parcels/${parcelId}/exploitation`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Obtiene los cultivos asociados a una parcela
   * @param {number} parcelId
   * @returns {Promise<Array>} Lista de cultivos
   */
  async getParcelCultivations(parcelId) {
    try {
      const response = await apiClient.get(`/parcels/${parcelId}/cultivations`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Obtiene el historial de usos de suelo de una parcela
   * @param {number} parcelId
   * @returns {Promise<Array>} Lista de usos históricos
   */
  async getParcelLandUseHistory(parcelId) {
    try {
      const response = await apiClient.get(`/parcels/${parcelId}/land-use-history`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Sube un documento asociado a la parcela (imagen, informe, etc.)
   * @param {number} parcelId
   * @param {File} file
   * @param {string} type Tipo de documento (image, report, etc.)
   * @returns {Promise<Object>} Respuesta del servidor
   */
  async uploadParcelDocument(parcelId, file, type = 'image') {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', type);

      const response = await apiClient.post(
        `/parcels/${parcelId}/documents`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Obtiene la URL de un documento de la parcela
   * @param {number} parcelId
   * @param {string} documentId
   * @returns {string} URL completa del documento
   */
  getParcelDocumentUrl(parcelId, documentId) {
    return `${apiClient.defaults.baseURL}/parcels/${parcelId}/documents/${documentId}`;
  },

  /**
   * Obtiene imágenes satelitales de la parcela
   * @param {number} parcelId
   * @param {Object} params Parámetros de búsqueda (fechas, tipo de imagen)
   * @returns {Promise<Array>} Lista de imágenes disponibles
   */
  async getSatelliteImages(parcelId, params = {}) {
    try {
      const response = await apiClient.get(`/parcels/${parcelId}/satellite-images`, { params });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Obtiene datos de productividad histórica de la parcela
   * @param {number} parcelId
   * @returns {Promise<Array>} Datos de productividad
   */
  async getProductivityData(parcelId) {
    try {
      const response = await apiClient.get(`/parcels/${parcelId}/productivity`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Manejo centralizado de errores
   * @param {Error} error
   * @returns {Error} Error formateado con detalles
   */
  handleError(error) {
    let errorMessage = 'Error al procesar la solicitud de parcela';
    let errorDetails = null;

    if (error.response) {
      errorMessage = error.response.data?.message ||
                   error.response.data?.error ||
                   `Error ${error.response.status}: ${error.response.statusText}`;

      // Para errores de validación
      if (error.response.data?.errors) {
        errorDetails = error.response.data.errors;
      }
    } else if (error.request) {
      errorMessage = 'El servidor no respondió';
    } else if (error.message) {
      errorMessage = error.message;
    }

    const formattedError = new Error(errorMessage);
    formattedError.details = errorDetails;
    formattedError.status = error.response?.status;

    return formattedError;
  },

  /**
   * Busca parcelas por criterios avanzados
   * @param {Object} criteria Criterios de búsqueda
   * @returns {Promise<Array>} Lista de parcelas que coinciden
   */
  async searchParcels(criteria) {
    try {
      const response = await apiClient.post('/parcels/search', criteria);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  /**
   * Obtiene estadísticas de parcelas
   * @param {Object} filters Filtros opcionales
   * @returns {Promise<Object>} Estadísticas agregadas
   */
  async getParcelStatistics(filters = {}) {
    try {
      const response = await apiClient.get('/parcels/statistics', { params: filters });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }
};

export default ParcelAPI;