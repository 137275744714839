import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import logo from '../assets/logo.png'; // Ajusta la ruta

export default function Header() {
  return (
    <AppBar position="static" sx={{ bgcolor: 'primary.main', py: 2 }}>
      <Toolbar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            width: '100%',
            maxWidth: 800,
            mx: 'auto',
            textAlign: 'center',
            flexDirection: 'column'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img src={logo} alt="Logo" style={{ height: 50 }} />
            <Typography variant="h1" sx={{ color: 'white' }}>
              AgroAdmin
            </Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ color: 'white' }}>
            Sistema de gestión de datos
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}