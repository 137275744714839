import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#20492f', // green-dark
        light: '#33be52', // green-light
        contrastText: '#ffffff', // texto blanco para botones
      },
      secondary: {
        main: '#565656', // grey-dark
        light: '#E3E2E2', // grey-light
      },
      background: {
        default: '#fafafa', // body-bg
        paper: '#ffffff', // fondo de componentes como la sidebar
      },
      divider: '#E3E2E2', // color para divisores
      },
    typography: {
      fontFamily: '"Poppins", sans-serif',
      h1: {
        fontSize: '2rem',
        fontWeight: 700,
      },
      h2: {
        color: '#20492f',
        fontSize: '1.5rem',
      },
      body1: {
        color: '#565656',
        fontSize: '1rem',
        lineHeight: 1.6,
      },
    },
    components: {
      MuiListItem: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFFFFF',
            margin: '8px 0',
            padding: '12px',
            borderLeft: '5px solid #33be52',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  },
  esES
);

const imageUploadStyle = {
  position: 'relative',
  '&:hover .image-overlay': {
    opacity: 1,
  },
};

const imageOverlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  transition: 'opacity 0.3s',
  borderRadius: '50%',
};

export { theme, imageUploadStyle, imageOverlayStyle };