import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://terrasense.servebeer.com:8090/api'

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
})

// Sistema mejorado de gestión de CSRF
let csrfToken = null
let csrfPromise = null

// Variables para controlar el refresco del token
let isRefreshing = false
let failedRequestsQueue = []

/**
 * Obtiene un nuevo token CSRF del servidor
 */
const obtainCsrfToken = async () => {
  try {
    // Limpiar cookie anterior (mejor compatibilidad entre dominios)
    document.cookie = 'XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

    const cookie = document.cookie
      .split('; ')
      .find((row) => row.trim().startsWith('XSRF-TOKEN='))

    csrfToken = cookie?.split('=')[1]
    return csrfToken
  } catch (error) {
    throw new Error('Error de seguridad. Intenta recargar la página.')
  } finally {
    csrfPromise = null
  }
}

/**
 * Refresca el token de acceso usando el refresh token
 */
const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken')
    if (!refreshToken) {
      throw new Error('No hay refresh token disponible')
    }

    const deviceInfo = navigator.userAgent // O puedes usar una librería para obtener más detalles del dispositivo

    const response = await apiClient.post('/auth/refresh', {
      refreshToken,
      deviceInfo
    })

    const { accessToken, refreshToken: newRefreshToken } = response.data

    // Almacenar los nuevos tokens
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', newRefreshToken)

    return accessToken
  } catch (error) {
    // Limpiar tokens y redirigir a login si el refresh falla
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    throw error
  }
}

/**
 * Procesa la cola de peticiones fallidas
 */
const processQueue = (error, token = null) => {
  failedRequestsQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedRequestsQueue = []
}

/**
 * Interceptor para añadir headers necesarios
 */
apiClient.interceptors.request.use(
  async (config) => {
    // Agregar token de autenticación si existe
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    // Simplificar manejo de CSRF solo para métodos que lo necesitan
    if (['post', 'put', 'patch', 'delete'].includes(config.method?.toLowerCase())) {
      try {
        if (!csrfToken && !csrfPromise) {
          csrfPromise = obtainCsrfToken().finally(() => {
            csrfPromise = null
          })
          csrfToken = await csrfPromise
        }
        config.headers['X-XSRF-TOKEN'] = csrfToken
      } catch (error) {
        // No bloquear la solicitud en desarrollo
        if (process.env.NODE_ENV === 'production') {
          throw error
        }
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * Interceptor para manejar respuestas
 */
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    // Manejar errores CSRF
    if (
      error.response?.status === 403 &&
      error.response.data?.error?.toLowerCase().includes('csrf') &&
      !originalRequest._retry
    ) {
      try {
        originalRequest._retry = true
        csrfToken = null
        csrfPromise = null

        await obtainCsrfToken()
        return apiClient(originalRequest)
      } catch (e) {
        return Promise.reject(
          new Error('Problema de seguridad persistente. Recarga la aplicación.')
        )
      }
    }

    // Manejar token expirado
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== '/auth/generate' &&
      originalRequest.url !== '/auth/refresh'
    ) {
      // Si ya estamos refrescando el token, encolamos la petición
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({ resolve, reject })
        }).then(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          return apiClient(originalRequest)
        }).catch(err => {
          return Promise.reject(err)
        })
      }

      isRefreshing = true
      originalRequest._retry = true

      try {
        const newAccessToken = await refreshAccessToken()

        // Actualizar el header de la petición original
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`

        // Procesar la cola de peticiones pendientes
        processQueue(null, newAccessToken)

        // Reintentar la petición original
        return apiClient(originalRequest)
      } catch (refreshError) {
        // Si falla el refresco, procesamos la cola con error
        processQueue(refreshError)

        // Redirigir a login o manejar el error según tu aplicación
        if (refreshError.message !== 'No hay refresh token disponible') {
          console.error('Error al refrescar token:', refreshError)
        }

        return Promise.reject(refreshError)
      } finally {
        isRefreshing = false
      }
    }

    return Promise.reject(error)
  }
)

export default apiClient