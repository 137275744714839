import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Card, CardContent, Typography, CardActionArea } from '@mui/material';
import {
  People as PeopleIcon,
  Business as BusinessIcon,
  Map as MapIcon,
  Grass as GrassIcon,
  LocalFlorist as LocalFloristIcon,
  WaterDrop as WaterDropIcon,
  Build as BuildIcon,
  DirectionsCar as DirectionsCarIcon,
  MedicalServices as MedicalServicesIcon,
  Devices as DevicesIcon
} from '@mui/icons-material';
import '../css/SectionsWall.css';

const cards = [
  { title: 'Usuarios', path: '/users', icon: <PeopleIcon /> },
  { title: 'Explotaciones', path: '/explotations', icon: <BusinessIcon /> },
  { title: 'Parcelas', path: '/parcels', icon: <MapIcon /> },
  { title: 'Cultivos', path: '/cultivations', icon: <GrassIcon /> },
  { title: 'Producciones', path: '/productions', icon: <LocalFloristIcon /> },
  { title: 'Riegos', path: '/irrigations', icon: <WaterDropIcon /> },
  { title: 'Herramientas', path: '/tools', icon: <BuildIcon /> },
  { title: 'Vehículos', path: '/vehicles', icon: <DirectionsCarIcon /> },
  { title: 'Tratamientos', path: '/treatments', icon: <MedicalServicesIcon /> },
  { title: 'Dispositivos', path: '/devices', icon: <DevicesIcon /> },
];

const SectionsWall = () => {
  const navigate = useNavigate();

  return (
    <Box className="sections-wall-container">
      <Box className="sections-wall-grid-container">
        <Grid container spacing={3} className="sections-wall-grid">
          {cards.map((card, index) => (
            <Grid key={index} sx={{
              width: {
                xs: '100%',    // 100% en pantallas extra pequeñas
                sm: '50%',     // 50% en pantallas pequeñas
                md: '25%',     // 25% en pantallas medianas
              },
              display: 'flex'
            }}>
              <Card className="sections-wall-card" sx={{ width: '100%' }}>
                <CardActionArea
                  onClick={() => navigate(card.path)}
                  sx={{ height: '100%', display: 'flex' }}
                >
                  <CardContent className="sections-wall-card-content">
                    <Box
                      className="sections-wall-icon"
                      sx={{
                        '& svg': {
                          fontSize: '3.5rem',
                          color: (theme) => theme.palette.primary.main,
                          transition: 'all 0.3s ease',
                        },
                        '&:hover svg': {
                          color: (theme) => theme.palette.secondary.main,
                          transform: 'scale(1.1)'
                        }
                      }}
                    >
                      {card.icon}
                    </Box>
                    <Typography variant="h6" className="sections-wall-card-title">
                      {card.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SectionsWall;