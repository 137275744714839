import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  IconButton,
  Tooltip,
  Avatar,
  Chip,
  TableSortLabel
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, VerifiedUser as VerifiedIcon, Warning as WarningIcon } from '@mui/icons-material';
import UserAPI from '../../../services/userAPI';

const UserTable = ({
  users,
  loading,
  order,
  orderBy,
  page,
  rowsPerPage,
  totalUsers,
  onSort,
  onChangePage,
  onChangeRowsPerPage,
  onEdit,
  onDelete
}) => {
  const columns = [
    { id: 'avatar', label: '', sortable: false },
    { id: 'username', label: 'Usuario' },
    { id: 'email', label: 'Email' },
    { id: 'role', label: 'Rol' },
    { id: 'verified', label: 'Verificado', sortable: false },
    { id: 'actions', label: 'Acciones', sortable: false }
  ];

  const handleAvatarError = (e, username) => {
    e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(username)}&background=random`;
    e.target.onerror = null;
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id}>
                  {column.sortable === false ? (
                    column.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => onSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}

            {!loading && users.length === 0 && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No se encontraron usuarios
                </TableCell>
              </TableRow>
            )}

            {!loading && users.map(user => (
              <TableRow key={user.id} hover>
                <TableCell>
                  <Avatar
                    src={user.avatarUrl ? UserAPI.getUserAvatarUrl(user.username) : ''}
                    alt={user.username}
                    onError={(e) => handleAvatarError(e, user.username)}
                    sx={{ width: 40, height: 40 }}
                  />
                </TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Chip
                    label={user.role}
                    color={user.role === 'ADMIN' ? 'primary' : 'default'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  {user.verified ? (
                    <VerifiedIcon color="success" />
                  ) : (
                    <WarningIcon color="warning" />
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Editar">
                    <IconButton onClick={() => onEdit(user)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton onClick={() => onDelete(user)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalUsers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </>
  );
};

export default UserTable;