import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'

export const createVectorLayer = (source) => {
  return new VectorLayer({
    source: source,
    style: {
      'fill-color': 'rgba(51, 136, 255, 0.2)',
      'stroke-color': '#3388ff',
      'stroke-width': 3,
    },
  })
}

export const createVectorSource = () => {
  return new VectorSource()
}
