import React from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { Box } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { theme } from './theme'
import { AuthProvider } from './contexts/AuthContext'
import Footer from './components/Footer'
import LoginPage from './pages/LoginPage'
import DashboardPage from './pages/DashboardPage'
import HomePage from './pages/HomePage'
import UsersPage from './pages/UsersPage'
import ExplotationsPage from './pages/ExplotationsPage'
import ParcelsPage from './pages/ParcelsPage'
import CultivationsPage from './pages/CultivationsPage'
import ProductionsPage from './pages/ProductionsPage'
import IrrigationsPage from './pages/IrrigationsPage'
import ToolsPage from './pages/ToolsPage'
import VehiclesPage from './pages/VehiclesPage'
import TreatmentsPage from './pages/TreatmentsPage'
import DevicesPage from './pages/DevicesPage'
import RegisterPage from './pages/RegisterPage'
import PrivateRoute from './components/PrivateRoute'
import AuthenticatedLayout from './components/AuthenticatedLayout'

// Componente de diseño compartido para páginas públicas
const PublicLayout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: 1200,
          mx: 'auto',
          width: '100%',
          pb: 10,
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  )
}

// Configuración del router
const router = createBrowserRouter([
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'register',
        element: <RegisterPage />,
      },
    ],
  },
  {
    path: '/',
    element: <AuthenticatedLayout />,
    children: [
      {
        path: 'dashboard',
        element: (
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'users',
        element: (
          <PrivateRoute>
            <UsersPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'explotations',
        element: (
          <PrivateRoute>
            <ExplotationsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'parcels',
        element: (
          <PrivateRoute>
            <ParcelsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'cultivations',
        element: (
          <PrivateRoute>
            <CultivationsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'productions',
        element: (
          <PrivateRoute>
            <ProductionsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'irrigations',
        element: (
          <PrivateRoute>
            <IrrigationsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'tools',
        element: (
          <PrivateRoute>
            <ToolsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'vehicles',
        element: (
          <PrivateRoute>
            <VehiclesPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'treatments',
        element: (
          <PrivateRoute>
            <TreatmentsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'devices',
        element: (
          <PrivateRoute>
            <DevicesPage />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
])

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <RouterProvider router={router} />
        </LocalizationProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App