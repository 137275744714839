import apiClient from './apiClient';

/**
 * Servicio de Autenticación Mejorado
 * Maneja login, refresh tokens, logout y verificación de sesión
 */
export const authService = {
  /**
   * Inicia sesión y obtiene tokens de acceso
   * @param {string} email - Correo electrónico del usuario
   * @param {string} password - Contraseña del usuario
   * @param {string} [deviceType] - Tipo de dispositivo (web/mobile/custom)
   * @returns {Promise<{accessToken: string, refreshToken: string, expiresIn: number, userData: object}>}
   */
  async login(email, password, deviceType = this.detectDeviceType()) {
    try {
      // 1. Intenta obtener token CSRF
      try {
        await apiClient.get('/csrf');
      } catch (csrfError) {
        // No impide el login si falla CSRF
      }

      // 2. Realizar login con deviceInfo
      const response = await apiClient.post('/auth/generate', {
        email,
        password,
        deviceInfo: this.generateDeviceInfo(deviceType)
      }, {
        timeout: 15000,
        withCredentials: true
      });

      if (!response.data?.accessToken) {
        throw new Error('La respuesta del servidor no contiene token de acceso');
      }

      // 3. Almacenar tokens
      this.storeTokens({
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken
      });

      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        expiresIn: response.data.expiresIn,
        userData: response.data.user
      };

    } catch (error) {
      const errorMessage = this.handleAuthError(error);
      throw new Error(errorMessage);
    }
  },

  /**
   * Renueva el token de acceso usando el refresh token
   * @param {string} refreshToken - Token de refresco
   * @returns {Promise<{accessToken: string, refreshToken: string, expiresIn: number}>}
   */
  async refreshToken(refreshToken) {
    try {
      const deviceType = this.detectDeviceType();
      const response = await apiClient.post('/auth/refresh', {
        refreshToken,
        deviceInfo: this.generateDeviceInfo(deviceType)
      }, {
        timeout: 10000,
        withCredentials: true
      });

      if (!response.data?.accessToken) {
        throw new Error('Respuesta de renovación inválida');
      }

      this.storeTokens({
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken || refreshToken
      });

      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken || refreshToken,
        expiresIn: response.data.expiresIn
      };
    } catch (error) {
      console.error('Error refrescando token:', error);
      this.clearTokens();
      throw new Error(this.handleRefreshError(error));
    }
  },

  /**
   * Cierra la sesión revocando el token
   * @param {string} accessToken - Token de acceso actual
   * @returns {Promise<boolean>}
   */
  async logout(accessToken) {
    try {
      await apiClient.post('/auth/revoke', {
        token: accessToken
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        withCredentials: true
      });

      this.clearTokens();
      return true;
    } catch (error) {
      console.warn('Error al cerrar sesión:', error);
      this.clearTokens();
      return false;
    }
  },

  /**
   * Verifica si un token es válido
   * @param {string} accessToken - Token a verificar
   * @returns {Promise<{isValid: boolean, userData?: object}>}
   */
  async verifyToken(accessToken) {
    try {
      const response = await apiClient.get('/auth/verify', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        withCredentials: true,
        timeout: 5000
      });

      return {
        isValid: response.data?.isValid === true,
        userData: response.data?.user
      };
    } catch (error) {
      console.error('Error verificando token:', error);
      return { isValid: false };
    }
  },

  /* ===== Métodos auxiliares ===== */

  /**
   * Genera información del dispositivo consistente
   * @param {string} deviceType - Tipo de dispositivo
   * @returns {string}
   */
  generateDeviceInfo(deviceType) {
    const deviceMap = {
      web: `Web-Browser/${navigator.platform}`,
      android: 'Android-App',
      ios: 'iOS-App',
      mobile: 'Mobile-App'
    };

    return deviceMap[deviceType] || deviceType;
  },

  /**
   * Detecta el tipo de dispositivo automáticamente
   * @returns {string}
   */
  detectDeviceType() {
    const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) return 'android';
    if (/iPhone|iPad|iPod/i.test(userAgent)) return 'ios';
    if (/Mobile/i.test(userAgent)) return 'mobile';
    return 'web';
  },

  /**
   * Maneja errores de autenticación
   * @param {Error} error
   * @returns {string}
   */
  /**
   * Maneja errores de autenticación con mensajes específicos para el usuario
   * @param {Error} error
   * @returns {string}
   */
  handleAuthError(error) {
    // Manejo de errores de red o sin respuesta
    if (!error.response) {
      return error.message.includes('Network Error')
        ? 'No hay conexión con el servidor'
        : 'Error de red. Verifica tu conexión.';
    }

    const status = error.response.status;
    const errorData = error.response.data || {};

    // Extraer el mensaje de error según la estructura de tu API
    const apiErrorMessage = errorData.error || errorData.message || '';

    // Mapeo de mensajes específicos de la API
    const errorMapping = {
      'Fallo de autenticación: Usuario o contraseña incorrectos': 'Usuario o contraseña incorrectos',
      'Error al generar los tokens': 'Error interno durante el login',
      'Refresh token no proporcionado': 'Sesión inválida. Por favor inicia sesión nuevamente.',
      'Token de refresco no encontrado': 'Sesión expirada. Por favor inicia sesión nuevamente.',
      'Token de refresco revocado': 'Sesión finalizada en otro dispositivo.',
      'Token de refresco expirado': 'Sesión expirada. Por favor inicia sesión nuevamente.',
      'Token no proporcionado': 'Token de acceso faltante',
      'Token no encontrado': 'Token inválido',
      'El token ya está revocado': 'Sesión ya finalizada',
      'Cuenta no verificada. Por favor verifica tu email.': 'Cuenta no verificada. Por favor verifica tu email antes de iniciar sesión.'
    };

    // Buscar mensaje traducido para el usuario
    if (apiErrorMessage && errorMapping[apiErrorMessage]) {
      return errorMapping[apiErrorMessage];
    }

    // Mensajes por defecto según status code
    const defaultStatusMessages = {
      400: 'Solicitud incorrecta',
      401: 'No autorizado',
      403: 'Acceso prohibido',
      429: 'Demasiados intentos. Por favor espera.',
      500: 'Error interno del servidor'
    };

    return defaultStatusMessages[status] || `Error ${status}: ${apiErrorMessage || 'Error desconocido'}`;
  },

  /**
   * Maneja errores de refresh token
   * @param {Error} error
   * @returns {string}
   */
  handleRefreshError(error) {
    if (error.response?.status === 401) {
      return 'Sesión expirada. Por favor inicia sesión nuevamente.';
    }
    return 'No se pudo renovar la sesión. Intenta iniciar sesión nuevamente.';
  },

  /**
   * Almacena tokens seguramente
   * @param {object} tokens
   */
  storeTokens({ accessToken, refreshToken }) {
    try {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    } catch (error) {
      console.error('Error almacenando tokens:', error);
    }
  },

  /**
   * Limpia los tokens almacenados
   */
  clearTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }
};

// Configuración del interceptor de respuestas
apiClient.interceptors.request.use(config => {
  // Añadir XSRF-TOKEN si existe en cookies
  const xsrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('XSRF-TOKEN='))
    ?.split('=')[1];

  if (xsrfToken) {
    config.headers['X-XSRF-TOKEN'] = xsrfToken;
  }

  return config;
}, error => {
  return Promise.reject(error);
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      error.message = authService.handleAuthError(error);
    }
    return Promise.reject(error);
  }
);

export default authService;