import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { authService } from '../services/authAPI';

export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe usarse dentro de un AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [tokens, setTokens] = useState(() => {
    if (typeof window !== 'undefined') {
      return {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken')
      };
    }
    return { accessToken: null, refreshToken: null };
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateUserFromToken = useCallback((token) => {
    if (!token) {
      setUser(null);
      return;
    }

    try {
      const decoded = jwtDecode(token);
      setUser({
        id: decoded.sub || decoded.userId,
        username: decoded.username,
        email: decoded.email,
        role: decoded.roles?.[0]?.replace('ROLE_', '') || 'user',
        expiresAt: decoded.exp ? new Date(decoded.exp * 1000) : null
      });
    } catch (error) {
      console.error('Error decodificando token:', error);
      setUser(null);
    }
  }, []);

  const handleLogin = async (username, password) => {
    setLoading(true);
    setError(null);

    try {
      const data = await authService.login(username, password);

      if (!data?.accessToken) {
        throw new Error('No se recibieron tokens válidos');
      }

      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      setTokens(data);
      updateUserFromToken(data.accessToken);

      return true;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Error de autenticación';
      setError(errorMessage);
      throw errorMessage;
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = useCallback(async () => {
    try {
      if (tokens.accessToken) {
        await authService.revokeToken(tokens.accessToken);
      }
    } catch (err) {
      console.error('Error al revocar token:', err);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setTokens({ accessToken: null, refreshToken: null });
      setUser(null);
      setError(null);
      setLoading(false);
    }
  }, [tokens.accessToken]);

  // Verifica el estado de autenticación al cargar
  useEffect(() => {
    let isMounted = true;

    const verifyAuthState = async () => {
      if (!tokens.accessToken) {
        if (isMounted) setLoading(false);
        return;
      }

      try {
        const { isValid } = await authService.verifyToken(tokens.accessToken);

        if (!isValid && isMounted) {
          await handleLogout();
          return;
        }

        if (isMounted) {
          updateUserFromToken(tokens.accessToken);

          // Verificar si el token está próximo a expirar
          const decoded = jwtDecode(tokens.accessToken);
          const expiresSoon = decoded.exp && (decoded.exp * 1000 - Date.now() < 5 * 60 * 1000);

          if (expiresSoon && tokens.refreshToken) {
            try {
              const newTokens = await authService.refreshToken(tokens.refreshToken);
              localStorage.setItem('accessToken', newTokens.accessToken);
              localStorage.setItem('refreshToken', newTokens.refreshToken);
              setTokens(newTokens);
              updateUserFromToken(newTokens.accessToken);
            } catch (refreshError) {
              console.error('Error refrescando token:', refreshError);
              await handleLogout();
            }
          }
        }
      } catch (err) {
        console.error('Error verificando autenticación:', err);
        if (isMounted) await handleLogout();
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    verifyAuthState();

    return () => {
      isMounted = false;
    };
  }, [tokens.accessToken, handleLogout, updateUserFromToken]);

  // Interceptor para manejar tokens expirados
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (tokens.accessToken) {
          config.headers.Authorization = `Bearer ${tokens.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 &&
            !originalRequest._retry &&
            tokens.refreshToken) {

          originalRequest._retry = true;

          try {
            const data = await authService.refreshToken(tokens.refreshToken);

            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
            setTokens(data);
            updateUserFromToken(data.accessToken);

            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
            return axios(originalRequest);
          } catch (refreshError) {
            await handleLogout();
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [tokens, handleLogout, updateUserFromToken]);

  // Verificación periódica del token (cada minuto)
  useEffect(() => {
    const interval = setInterval(() => {
      if (tokens.accessToken) {
        const decoded = jwtDecode(tokens.accessToken);
        const isExpired = decoded.exp && (decoded.exp * 1000 < Date.now());

        if (isExpired) {
          handleLogout();
        }
      }
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [tokens.accessToken, handleLogout]);

  return (
      <AuthContext.Provider value={{
        user,
        tokens,
        loading,
        error,
        isAuthenticated: !!user && !!tokens.accessToken,
        handleLogin,
        handleLogout,
        updateUserFromToken,
        hasRole: (requiredRole) => {
          if (!user?.role) return false;
          return user.role === requiredRole;
        }
      }}>
        {children}
      </AuthContext.Provider>
    );
  };