import GeoJSON from 'ol/format/GeoJSON'

export const loadGeoJSONFeatures = (geojson, vectorSource) => {
  try {
    const parsedGeoJson =
      typeof geojson === 'string' ? JSON.parse(geojson) : geojson
    const features = new GeoJSON().readFeatures(parsedGeoJson)
    vectorSource.clear()
    vectorSource.addFeatures(features)
    return features
  } catch (error) {
    console.error('Error parsing GeoJSON:', error)
    return []
  }
}

export const fitToFeatures = (map, vectorSource) => {
  if (map && vectorSource.getFeatures().length > 0) {
    map.getView().fit(vectorSource.getExtent(), {
      padding: [50, 50, 50, 50],
      maxZoom: 18,
    })
  }
}
