import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  useTheme,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import {
  Visibility,
  VisibilityOff,
  Lock as LockIcon,
  Person as PersonIcon,
  Email as EmailIcon,
} from '@mui/icons-material'
import PropTypes from 'prop-types'
import './LoginWidget.css'

const LoginWidget = ({
  onLogin,
  loading = false,
  initialError = null,
  showTitle = true,
  elevation = 3,
  sx = {},
  onForgotPassword,
  onRegister,
  enableEmail = false,
  rememberMe = false,
  socialLogins = false,
  customHeader,
  customFooter,
}) => {
  const theme = useTheme()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(initialError)
  const [showPassword, setShowPassword] = useState(false)
  const [remember, setRemember] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({
    username: false,
    password: false,
  })

  // Sincronizar errores iniciales
  useEffect(() => {
    setError(initialError)
  }, [initialError])

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Resetear errores
    setError(null)
    setFieldErrors({
      username: false,
      password: false,
    })

    // Validación básica de campos
    let hasError = false
    const newFieldErrors = { ...fieldErrors }

    if (!username.trim()) {
      newFieldErrors.username = true
      hasError = true
    }

    if (!password.trim()) {
      newFieldErrors.password = true
      hasError = true
    }

    if (hasError) {
      setFieldErrors(newFieldErrors)
      setError('Por favor completa todos los campos')
      return
    }

    try {
      await onLogin({
        username,
        password,
        ...(enableEmail && { email: username }),
        ...(rememberMe && { remember }),
      })
    } catch (err) {
      // Marcar campos como inválidos si las credenciales son incorrectas
      if (
        err.message?.toLowerCase().includes('credencial') ||
        err.message?.toLowerCase().includes('inválid')
      ) {
        setFieldErrors({
          username: true,
          password: true,
        })
      }
      // El error real se maneja en LoginPage
    }
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
    setFieldErrors({ ...fieldErrors, username: false })
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    setFieldErrors({ ...fieldErrors, password: false })
  }

  return (
    <Paper
      className="login-widget-container"
      elevation={elevation}
      sx={{
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '12px',
        padding: theme.spacing(4),
        width: '100%',
        maxWidth: '450px',
        ...sx,
      }}
    >
      {customHeader ||
        (showTitle && (
          <Box
            className="login-widget-header"
            sx={{
              textAlign: 'center',
              mb: 4,
            }}
          >
            <LockIcon
              sx={{
                fontSize: 40,
                color: theme.palette.primary.main,
                mb: 1,
              }}
            />
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.text.primary,
                fontWeight: 600,
                fontSize: '1.5rem',
              }}
            >
              Iniciar Sesión
            </Typography>
          </Box>
        ))}

      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ width: '100%' }}
      >
        <TextField
          label={enableEmail ? 'Email' : 'Usuario'}
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={handleUsernameChange}
          autoComplete={enableEmail ? 'email' : 'username'}
          error={fieldErrors.username}
          helperText={
            fieldErrors.username
              ? enableEmail
                ? 'Ingresa tu email'
                : 'Ingresa tu usuario'
              : ''
          }
          disabled={loading}
          sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: fieldErrors.username
                  ? theme.palette.error.main
                  : theme.palette.divider,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.light,
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {enableEmail ? (
                  <EmailIcon
                    sx={{
                      color: fieldErrors.username
                        ? theme.palette.error.main
                        : theme.palette.text.secondary,
                    }}
                  />
                ) : (
                  <PersonIcon
                    sx={{
                      color: fieldErrors.username
                        ? theme.palette.error.main
                        : theme.palette.text.secondary,
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Contraseña"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={handlePasswordChange}
          autoComplete="current-password"
          error={fieldErrors.password}
          helperText={fieldErrors.password ? 'Ingresa tu contraseña' : ''}
          disabled={loading}
          sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: fieldErrors.password
                  ? theme.palette.error.main
                  : theme.palette.divider,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.light,
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon
                  sx={{
                    color: fieldErrors.password
                      ? theme.palette.error.main
                      : theme.palette.text.secondary,
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePassword}
                  edge="end"
                  size="small"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {rememberMe && (
          <Box display="flex" alignItems="center" mb={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={remember}
                  onChange={(e) => setRemember(e.target.checked)}
                  color="primary"
                  size="small"
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Recordar mi sesión
                </Typography>
              }
            />
          </Box>
        )}

        {error && (
          <Alert
            severity="error"
            sx={{
              mb: 2,
              borderRadius: '8px',
              alignItems: 'center',
            }}
          >
            {error}
          </Alert>
        )}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          sx={{
            py: 1.5,
            mb: 2,
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 600,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          }}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
        >
          {loading ? 'Ingresando...' : 'Ingresar'}
        </Button>

        {(onForgotPassword || onRegister) && (
          <Box display="flex" justifyContent="space-between" mt={2}>
            {onForgotPassword && (
              <Button
                onClick={onForgotPassword}
                size="small"
                disabled={loading}
                sx={{
                  color: theme.palette.text.secondary,
                  textTransform: 'none',
                  padding: 0,
                  minWidth: 'auto',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: theme.palette.primary.main,
                  },
                }}
              >
                ¿Olvidaste tu contraseña?
              </Button>
            )}
            {onRegister && (
              <Button
                onClick={onRegister}
                size="small"
                disabled={loading}
                sx={{
                  color: theme.palette.primary.main,
                  textTransform: 'none',
                  padding: 0,
                  minWidth: 'auto',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}
              >
                Crear cuenta
              </Button>
            )}
          </Box>
        )}

        {customFooter && <Box mt={3}>{customFooter}</Box>}
      </Box>
    </Paper>
  )
}

LoginWidget.propTypes = {
  onLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  initialError: PropTypes.string,
  showTitle: PropTypes.bool,
  elevation: PropTypes.number,
  sx: PropTypes.object,
  onForgotPassword: PropTypes.func,
  onRegister: PropTypes.func,
  enableEmail: PropTypes.bool,
  rememberMe: PropTypes.bool,
  socialLogins: PropTypes.bool,
  customHeader: PropTypes.node,
  customFooter: PropTypes.node,
}

LoginWidget.defaultProps = {
  loading: false,
  initialError: null,
  showTitle: true,
  elevation: 1,
  enableEmail: false,
  rememberMe: false,
  socialLogins: false,
}

export default LoginWidget
