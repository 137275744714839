import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { CircularProgress, Box } from '@mui/material';

const PrivateRoute = ({ children }) => {
  const { tokens } = useContext(AuthContext);

  if (tokens.accessToken === undefined) {
    // Puedes añadir un spinner mientras verificas el token
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return tokens.accessToken ? children : <Navigate to="/login" />;
};

export default PrivateRoute;