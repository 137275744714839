import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Drawer, Toolbar, useTheme, useMediaQuery } from '@mui/material';
import { SidebarMenu } from './SidebarMenu';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthContext } from '../contexts/AuthContext';

const drawerWidth = 240;
const collapsedWidth = 64;

const AuthenticatedLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [desktopOpen, setDesktopOpen] = React.useState(true);
  const { isAuthenticated } = React.useContext(AuthContext);

  const handleDrawerToggle = () => {
    if (isMobile) {
      setMobileOpen(!mobileOpen);
    } else {
      setDesktopOpen(!desktopOpen);
    }
  };

  if (!isAuthenticated) {
    return <Outlet />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* Barra lateral */}
      <Box
        component="nav"
        sx={{
          width: { sm: desktopOpen ? drawerWidth : collapsedWidth },
          flexShrink: { sm: 0 }
        }}
      >
        {/* Versión móvil */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            },
          }}
        >
          <SidebarMenu isOpen={true} toggleDrawer={handleDrawerToggle} />
        </Drawer>

        {/* Versión desktop */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: desktopOpen ? drawerWidth : collapsedWidth,
              overflowX: 'hidden',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          }}
          open
        >
          <SidebarMenu isOpen={desktopOpen} toggleDrawer={handleDrawerToggle} />
        </Drawer>
      </Box>

      {/* Contenido principal */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${desktopOpen ? drawerWidth : collapsedWidth}px)` },
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: { sm: `${desktopOpen ? drawerWidth : collapsedWidth}px` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default AuthenticatedLayout;