import apiClient from './apiClient';

const UserAPI = {
  async fetchAllUsers() {
    try {
      const response = await apiClient.get('/users');
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async fetchUserByUsername(username) {
    try {
      const response = await apiClient.get(`/users/${username}`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async createUser(userData) {
    try {
      const response = await apiClient.post('/users', userData);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async updateUser(username, userData) {
    try {
      const response = await apiClient.put(`/users/${username}`, userData);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async deleteUser(username) {
    try {
      await apiClient.delete(`/users/${username}`);
      return true;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  getUserImageUrl(username) {
    return `${apiClient.defaults.baseURL}/users/${username}/image`;
  },

  getUserAvatarUrl(username) {
    return `${apiClient.defaults.baseURL}/users/${username}/avatar`;
  },

  async uploadUserImage(username, imageFile) {
    try {
      if (!imageFile || !(imageFile instanceof File)) {
        throw new Error('Archivo de imagen no válido');
      }

      const formData = new FormData();
      formData.append('image', imageFile);

      const response = await apiClient.put(
        `/users/${username}/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async uploadUserAvatar(username, avatarFile) {
    try {
      if (!avatarFile || !(avatarFile instanceof File)) {
        throw new Error('Archivo de avatar no válido');
      }

      const formData = new FormData();
      formData.append('avatar', avatarFile);

      const response = await apiClient.put(
        `/users/${username}/avatar`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async deleteUserImage(username) {
    try {
      await apiClient.delete(`/users/${username}/image`);
      return true;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async deleteUserAvatar(username) {
    try {
      await apiClient.delete(`/users/${username}/avatar`);
      return true;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async getUserExploitations(username) {
    try {
      const response = await apiClient.get(`/users/${username}/exploitations`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  handleError(error) {
    let errorMessage = 'Error al procesar la solicitud';
    let errorDetails = null;

    if (error.response) {
      errorMessage = error.response.data?.message ||
                   error.response.data?.error ||
                   `Error ${error.response.status}: ${error.response.statusText}`;

      if (error.response.data?.errors) {
        errorDetails = error.response.data.errors;
      }
    } else if (error.request) {
      errorMessage = 'El servidor no respondió';
    } else if (error.message) {
      errorMessage = error.message;
    }

    const formattedError = new Error(errorMessage);
    formattedError.details = errorDetails;
    formattedError.status = error.response?.status;

    return formattedError;
  }
};

export default UserAPI;