import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  IconButton,
  Tooltip,
  Chip,
  TableSortLabel,
  LinearProgress,
} from '@mui/material'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'

const ExploitationTable = ({
  exploitations,
  loading,
  order,
  orderBy,
  page,
  rowsPerPage,
  totalExploitations,
  onSort,
  onChangePage,
  onChangeRowsPerPage,
  onEdit,
  onDelete,
}) => {
  const columns = [
    { id: 'name', label: 'Nombre' },
    { id: 'nif', label: 'NIF' },
    { id: 'city', label: 'Ciudad' },
    { id: 'totalArea', label: 'Área (ha)', align: 'right' },
    { id: 'actions', label: 'Acciones', sortable: false, align: 'center' },
  ]

  return (
    <>
      <TableContainer component={Paper}>
        {loading && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align || 'left'}>
                  {column.sortable === false ? (
                    column.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => onSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && exploitations.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}

            {!loading && exploitations.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No se encontraron explotaciones
                </TableCell>
              </TableRow>
            )}

            {!loading &&
              exploitations.map((exploitation) => (
                <TableRow key={exploitation.id} hover>
                  <TableCell>{exploitation.name}</TableCell>
                  <TableCell>
                    <Chip
                      label={exploitation.nif}
                      color="default"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{exploitation.city}</TableCell>
                  <TableCell align="right">{exploitation.totalArea}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Editar">
                      <IconButton onClick={() => onEdit(exploitation)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <IconButton
                        onClick={() => onDelete(exploitation)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalExploitations}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </>
  )
}

export default ExploitationTable
