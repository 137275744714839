import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import { fromLonLat } from 'ol/proj'
import { defaults as defaultInteractions } from 'ol/interaction'
import { defaults as defaultControls } from 'ol/control'

// Importaciones de los nuevos módulos
import { createBaseLayers } from './layers/BaseLayers'
import { createVectorLayer, createVectorSource } from './layers/VectorLayer'
import { adjustLayersBasedOnZoom } from './layers/LayerManager'
import { loadGeoJSONFeatures, fitToFeatures } from './layers/GeoJSONHandler'
import { getUserLocation } from './layers/LocationHandler'

const MapComponent = ({
  geojson,
  onChange,
  height = 300,
  initialPosition = null,
  initialZoom = 6,
  showInfoCard,
  onRecintoSelected,
  className = '',
}) => {
  const mapRef = useRef(null)
  const mapInstance = useRef(null)
  const vectorSource = useRef(createVectorSource())
  const [mapReady, setMapReady] = useState(false)
  const [userLocation, setUserLocation] = useState(null)
  const [loadingLocation, setLoadingLocation] = useState(false)

  // Capas base memoizadas
  const baseLayers = useMemo(createBaseLayers, [])

  // Obtener ubicación del usuario
  useEffect(() => {
    if (!initialPosition && !userLocation && !loadingLocation) {
      setLoadingLocation(true)
      getUserLocation()
        .then((location) => {
          setUserLocation(location)
          setLoadingLocation(false)
        })
        .catch((defaultLocation) => {
          setUserLocation(defaultLocation)
          setLoadingLocation(false)
        })
    }
  }, [initialPosition, userLocation, loadingLocation])

  // Función para mostrar información del recinto
  const showRecintoInfo = useCallback(
    (properties) => {
      const info = `Provincia: ${properties.provincia || 'Desconocido'}\nMunicipio: ${properties.municipio || 'Desconocido'}`
      if (showInfoCard) {
        showInfoCard('Información del Recinto', info)
      }
    },
    [showInfoCard],
  )

  // Inicializar el mapa
  useEffect(() => {
    if (!mapRef.current || mapInstance.current) return

    // Determinar la posición inicial
    const defaultPosition = initialPosition || userLocation || [0, 0]
    const center = fromLonLat(defaultPosition)

    const vectorLayer = createVectorLayer(vectorSource.current)

    // Configuración del mapa
    mapInstance.current = new Map({
      target: mapRef.current,
      layers: [
        baseLayers.osm,
        baseLayers.ign,
        baseLayers.esri,
        baseLayers.sigpac,
        vectorLayer,
      ],
      view: new View({
        center,
        zoom: initialZoom,
        minZoom: 7,
        maxZoom: 20,
      }),
      controls: defaultControls(),
      interactions: defaultInteractions(),
    })

    // Evento de zoom
    const handleZoomChange = () => {
      const zoom = mapInstance.current.getView().getZoom()
      adjustLayersBasedOnZoom(mapInstance.current, baseLayers, zoom)
    }

    mapInstance.current.getView().on('change:resolution', handleZoomChange)

    // Evento de clic para SIGPAC
    const clickHandler = async (evt) => {
      const zoom = mapInstance.current.getView().getZoom()
      if (zoom >= 17.5 && baseLayers.sigpac.getVisible()) {
        const view = mapInstance.current.getView()
        try {
          const url = baseLayers.sigpac
            .getSource()
            .getFeatureInfoUrl(
              evt.coordinate,
              view.getResolution(),
              view.getProjection(),
              { INFO_FORMAT: 'application/json' },
            )

          const response = await fetch(url)
          const data = await response.json()

          if (data.features?.length > 0) {
            const recintoInfo = {
              ...data.features[0].properties,
              coordinates: evt.coordinate, // Añadimos las coordenadas del clic
            }
            showRecintoInfo(recintoInfo)
            onRecintoSelected?.(recintoInfo)
          }
        } catch (error) {
          console.error('Error al obtener datos de la parcela:', error)
        }
      }
    }

    mapInstance.current.on('singleclick', clickHandler)
    setMapReady(true)

    return () => {
      if (mapInstance.current) {
        mapInstance.current.getView().un('change:resolution', handleZoomChange)
        mapInstance.current.un('singleclick', clickHandler)
        mapInstance.current.setTarget(undefined)
        mapInstance.current = null
      }
    }
  }, [initialPosition, initialZoom, userLocation])

  // Cargar GeoJSON
  useEffect(() => {
    if (!mapReady || !geojson || !mapInstance.current) return

    const features = loadGeoJSONFeatures(geojson, vectorSource.current)
    fitToFeatures(mapInstance.current, vectorSource.current)
  }, [geojson, mapReady])

  return (
    <div
      ref={mapRef}
      className={className}
      style={{
        height: `${height}px`,
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #e0e0e0',
        position: 'relative',
      }}
    >
      {loadingLocation && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255,255,255,0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div>Cargando tu ubicación...</div>
        </div>
      )}
    </div>
  )
}

export default React.memo(MapComponent)
