import axios from 'axios';

// 1. Configuración base
const API_URL = process.env.REACT_APP_API_URL || 'http://terrasense.servebeer.com:8090/api';

// 2. Creación de la instancia de axios
const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 30000, // 30 segundos de timeout
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true, // Para cookies de autenticación
});

// 3. Manejo del token CSRF
let csrfTokenCache = null;

const getCsrfToken = async () => {
  try {
    // Usar caché si está disponible
    if (csrfTokenCache) return csrfTokenCache;

    // Intentar obtener de las cookies
    const csrfCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('XSRF-TOKEN='));

    if (csrfCookie) {
      csrfTokenCache = csrfCookie.split('=')[1];
      return csrfTokenCache;
    }

    // Si no existe, solicitar nuevo token al servidor
    const token = localStorage.getItem('accessToken');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    await axios.get(`${API_URL}/csrf`, {
      withCredentials: true,
      headers
    });

    // Obtener el nuevo token de la cookie
    const newCsrfCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('XSRF-TOKEN='));

    if (!newCsrfCookie) {
      throw new Error('CSRF token no recibido');
    }

    csrfTokenCache = newCsrfCookie.split('=')[1];
    return csrfTokenCache;
  } catch (error) {
    console.error('Error obteniendo CSRF token:', error);
    throw new Error('No se pudo obtener el token CSRF');
  }
};

// 4. Interceptores de solicitud
apiClient.interceptors.request.use(async (config) => {
  // Agregar token de autenticación si existe
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // Agregar CSRF token para métodos no seguros (POST, PUT, PATCH, DELETE)
  if (['post', 'put', 'patch', 'delete'].includes(config.method.toLowerCase())) {
    try {
      const csrfToken = await getCsrfToken();
      config.headers['X-XSRF-TOKEN'] = csrfToken;
    } catch (error) {
      if (process.env.NODE_ENV === 'production') {
        return Promise.reject(error);
      }
      console.warn('Advertencia CSRF:', error.message);
    }
  }

  // Manejo especial para FormData (subida de archivos)
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// 5. Interceptores de respuesta
apiClient.interceptors.response.use(
  (response) => {
    // Puedes procesar respuestas exitosas aquí si es necesario
    return response;
  },
  (error) => {
    // Manejo centralizado de errores
    if (error.response) {
      switch (error.response.status) {
        case 401: // No autorizado
          console.warn('Sesión expirada o no autorizado');
          // Limpiar caché y redirigir
          csrfTokenCache = null;
          localStorage.removeItem('accessToken');
          window.location.href = '/login'; // Descomentar para redirección automática
          break;

        case 403: // Prohibido
          console.warn('Acceso denegado');
          break;

        case 404: // No encontrado
          console.warn('Recurso no encontrado');
          break;

        case 500: // Error del servidor
          console.error('Error interno del servidor');
          break;

        default:
          console.error(`Error HTTP ${error.response.status}`);
      }
    } else if (error.request) {
      console.error('No se recibió respuesta del servidor');
    } else {
      console.error('Error al configurar la solicitud:', error.message);
    }

    return Promise.reject(error);
  }
);

// 6. Funciones auxiliares
const buildQueryString = (params) => {
  const query = new URLSearchParams();
  for (const key in params) {
    if (Array.isArray(params[key])) {
      params[key].forEach(value => query.append(key, value));
    } else if (params[key] !== undefined && params[key] !== null) {
      query.append(key, params[key]);
    }
  }
  return query.toString();
};

// 7. Métodos del API Client
apiClient.getWithParams = async (url, params) => {
  const queryString = buildQueryString(params);
  const fullUrl = queryString ? `${url}?${queryString}` : url;
  return apiClient.get(fullUrl);
};

apiClient.uploadFile = async (url, file, fieldName = 'file', extraData = {}) => {
  const formData = new FormData();
  formData.append(fieldName, file);
  for (const key in extraData) {
    formData.append(key, extraData[key]);
  }
  return apiClient.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// 8. Exportación del cliente configurado
export default apiClient;