import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://terrasense.servebeer.com:8090/api',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

const getCsrfToken = async () => {
  try {
    const csrfCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('XSRF-TOKEN='));

    if (csrfCookie) return csrfCookie.split('=')[1];

    const token = localStorage.getItem('accessToken');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    await axios.get(`${process.env.REACT_APP_API_URL || 'http://terrasense.servebeer.com:8090/api'}/csrf`, {
      withCredentials: true,
      headers
    });

    const newCsrfCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('XSRF-TOKEN='));

    if (!newCsrfCookie) {
      throw new Error('CSRF token no recibido');
    }

    return newCsrfCookie.split('=')[1];
  } catch (error) {
    console.error('Error obteniendo CSRF token:', error);
    throw error;
  }
};

apiClient.interceptors.request.use(async config => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (['post', 'put', 'patch', 'delete'].includes(config.method.toLowerCase())) {
    try {
      const csrfToken = await getCsrfToken();
      config.headers['X-XSRF-TOKEN'] = csrfToken;
    } catch (error) {
      console.error('Error configurando CSRF:', error);
    }
  }

  return config;
});

/**
 * Servicio de Autenticación
 */
export const authService = {
  async login(username, password) {
    try {
      const response = await apiClient.post('/auth/generate', {
        username,
        password
      }, {
        timeout: 15000
      });

      if (!response.data?.token || !response.data?.refreshToken) {
        throw new Error('Estructura de tokens inválida');
      }

      return {
        accessToken: response.data.token,
        refreshToken: response.data.refreshToken,
        expiresIn: response.data.expiresIn
      };
    } catch (error) {
      let errorMessage = 'Error al iniciar sesión';

      if (error.code === 'ECONNABORTED') {
        errorMessage = 'Tiempo de espera agotado';
      } else if (error.response) {
        errorMessage = error.response.data?.message ||
                      error.response.data?.error ||
                      `Error ${error.response.status}`;
      } else if (error.message.includes('Network Error')) {
        errorMessage = 'Error de conexión con el servidor';
      }

      throw new Error(errorMessage);
    }
  },

  async refreshToken(refreshToken) {
    try {
      const response = await apiClient.post('/auth/refresh', {
        refreshToken
      });

      const newAccessToken = response.data?.token || response.data?.accessToken;
      if (!newAccessToken) {
        throw new Error('Formato de token inválido');
      }

      return {
        accessToken: newAccessToken,
        refreshToken: response.data?.refreshToken || refreshToken
      };
    } catch (error) {
      console.error('Error refrescando token:', error);
      throw new Error(
        error.response?.data?.message ||
        'No se pudo renovar la sesión. Por favor inicie sesión nuevamente'
      );
    }
  },

  async revokeToken(token) {
    try {
      await apiClient.post('/auth/revoke', { token }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        timeout: 5000
      });
      return true;
    } catch (error) {
      console.warn('Error al revocar token:', error);
      return false;
    }
  },

  async verifyToken(accessToken) {
    try {
      const response = await apiClient.get('/auth/verify', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        timeout: 5000
      });

      return response.data?.isValid === true ||
             response.data?.active === true ||
             response.status === 200;
    } catch (error) {
      console.error('Error verificando token:', error);
      return false;
    }
  }
};

// Interceptor global de respuestas
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      error.message = 'Error de red - Verifique su conexión';
    } else {
      switch (error.response.status) {
        case 400:
          error.message = error.response.data?.message || 'Solicitud inválida';
          break;
        case 401:
          error.message = 'Sesión expirada - Por favor inicie sesión nuevamente';
          break;
        case 403:
          error.message = 'Acceso denegado - No tiene permisos suficientes';
          break;
        case 500:
          error.message = 'Error interno del servidor';
          break;
        default:
          error.message = `Error ${error.response.status}`;
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;