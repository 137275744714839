import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  MenuItem,
  Typography,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  Box,
  CircularProgress,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import {
  Save as SaveIcon,
  Cancel as CancelIcon,
  ExpandMore as ExpandMoreIcon,
  LocationOn as LocationIcon,
  Terrain as TerrainIcon,
  CalendarToday as CalendarIcon,
  Info as InfoIcon,
  Map as MapIcon
} from '@mui/icons-material';
import MapComponent from '../../../components/map/MapComponent';
import '../ParcelsWidget.css';

const DEFAULT_POSITION = [-3.7038, 40.4168];

const INITIAL_PARCEL_STATE = {
  id: null,
  name: '',
  soilType: '',
  coordinates: '',
  geojson: '',
  area: 0,
  flightDate: new Date().toISOString().split('T')[0],
  cadastralMappingDate: '',
  province: '',
  municipality: '',
  aggregate: '',
  zone: '',
  polygon: 0,
  parcelNumber: 0,
  cadastralReference: '',
  slopePercentage: 0,
  altitude: 0,
  landUse: '',
  irrigationCoefficient: 0,
  incidents: '',
  region: '',
  initialPosition: DEFAULT_POSITION
};

const soilTypes = ['Arcilloso', 'Franco', 'Arenoso', 'Limoso', 'Franco-arcilloso'];
const landUses = ['Olivar intensivo', 'Olivar tradicional', 'Cereal', 'Viñedo', 'Frutales'];

const ParcelFormModal = ({ open, onClose, parcel, onSave, loading, isNew }) => {
  const [formData, setFormData] = useState(INITIAL_PARCEL_STATE);
  const [mapKey, setMapKey] = useState(Date.now());
  const [errors, setErrors] = useState({});
  const [mapLoading, setMapLoading] = useState(false);
  const [locationError, setLocationError] = useState(false);

  const getUserLocation = useCallback(() => {
    if (!isNew) return;

    setMapLoading(true);
    setLocationError(false);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { longitude, latitude } = position.coords;
          setFormData(prev => ({
            ...prev,
            initialPosition: [longitude, latitude],
            coordinates: `${latitude},${longitude}`
          }));
          setMapLoading(false);
        },
        (error) => {
          console.error('Error obteniendo ubicación:', error);
          setLocationError(true);
          setMapLoading(false);
          setFormData(prev => ({
            ...prev,
            initialPosition: DEFAULT_POSITION,
            coordinates: `${DEFAULT_POSITION[1]},${DEFAULT_POSITION[0]}`
          }));
        }
      );
    } else {
      console.warn('Geolocation no soportado por el navegador');
      setLocationError(true);
      setMapLoading(false);
      setFormData(prev => ({
        ...prev,
        initialPosition: DEFAULT_POSITION,
        coordinates: `${DEFAULT_POSITION[1]},${DEFAULT_POSITION[0]}`
      }));
    }
  }, [isNew]);

  useEffect(() => {
    if (open) {
      const initialData = isNew
        ? { ...INITIAL_PARCEL_STATE }
        : {
            ...INITIAL_PARCEL_STATE,
            ...parcel,
            initialPosition: parcel.coordinates
              ? parcel.coordinates.split(',').map(Number).reverse()
              : DEFAULT_POSITION
          };

      setFormData(initialData);
      setErrors({});
      setMapKey(Date.now());

      if (isNew) {
        getUserLocation();
      }
    }
  }, [open, isNew, parcel, getUserLocation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleMapChange = useCallback((geojson) => {
    setFormData(prev => ({
      ...prev,
      geojson,
      coordinates: extractCoordinatesFromGeoJSON(geojson)
    }));
  }, []);

  const extractCoordinatesFromGeoJSON = (geojson) => {
    if (!geojson) return '';
    try {
      const parsed = typeof geojson === 'string' ? JSON.parse(geojson) : geojson;
      const coordinates = parsed.features?.[0]?.geometry?.coordinates?.[0]?.[0];
      if (coordinates) {
        return `${coordinates[1]},${coordinates[0]}`;
      }
    } catch (e) {
      console.error('Error parsing GeoJSON:', e);
    }
    return '';
  };

  const handleRecintoSelected = useCallback((recintoInfo) => {
    console.log('Datos SIGPAC recibidos:', recintoInfo);

    setFormData(prev => ({
      ...prev,
      name: prev.name || `Parcela ${recintoInfo.poligono}-${recintoInfo.parcela}`,
      cadastralReference: recintoInfo.recinto || prev.cadastralReference,
      province: recintoInfo.provincia || prev.province,
      municipality: recintoInfo.municipio || prev.municipality,
      landUse: recintoInfo.uso || prev.landUse,
      area: parseFloat(recintoInfo.superficie) || prev.area,
      polygon: parseInt(recintoInfo.poligono) || prev.polygon,
      parcelNumber: parseInt(recintoInfo.parcela) || prev.parcelNumber,
      coordinates: recintoInfo.coordinates
        ? `${recintoInfo.coordinates[1]},${recintoInfo.coordinates[0]}`
        : prev.coordinates,
      initialPosition: recintoInfo.coordinates || prev.initialPosition,
      region: prev.region || 'Andalucía'
    }));
  }, []);

  const showRecintoInfo = useCallback((title, content) => {
    console.log(title, content);
  }, []);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Nombre es requerido';
    if (!formData.cadastralReference.trim()) newErrors.cadastralReference = 'Referencia catastral es requerida';
    if (formData.area <= 0) newErrors.area = 'Área debe ser mayor que 0';
    if (!formData.geojson) newErrors.geojson = 'Debe seleccionar un área en el mapa';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleSubmit = useCallback(() => {
    if (validateForm()) {
      const dataToSave = {
        ...formData,
        coordinates: formData.coordinates || extractCoordinatesFromGeoJSON(formData.geojson)
      };
      onSave(dataToSave);
    }
  }, [formData, onSave, validateForm]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      className="parcel-modal"
      PaperProps={{ className: "parcel-modal-paper" }}
    >
      <DialogTitle className="parcel-modal-title">
        <TerrainIcon className="parcel-modal-title-icon" />
        {isNew ? 'Nueva Parcela' : `Editar Parcela: ${formData.name || ''}`}
      </DialogTitle>

      {loading && <LinearProgress className="parcel-modal-progress" />}

      <DialogContent dividers className="parcel-modal-content">
        <Accordion defaultExpanded className="parcel-accordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className="parcel-accordion-summary">
            <Typography variant="h6" className="parcel-section-title">
              <InfoIcon className="parcel-section-icon" />
              Información Básica
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="parcel-accordion-details">
            <Grid container spacing={2} className="parcel-form-grid">
              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 12', md: 'span 6' } }}>
                <TextField
                  name="name"
                  label="Nombre de la parcela"
                  value={formData.name || ''}
                  onChange={handleChange}
                  fullWidth
                  required
                  error={!!errors.name}
                  helperText={errors.name}
                  className="parcel-text-field"
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 12', md: 'span 6' } }}>
                <TextField
                  name="cadastralReference"
                  label="Referencia Catastral"
                  value={formData.cadastralReference || ''}
                  onChange={handleChange}
                  fullWidth
                  required
                  error={!!errors.cadastralReference}
                  helperText={errors.cadastralReference}
                  className="parcel-text-field"
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 3' } }}>
                <TextField
                  name="area"
                  label="Área (ha)"
                  type="number"
                  value={formData.area || 0}
                  onChange={handleChange}
                  fullWidth
                  required
                  inputProps={{ min: 0.01, step: 0.01 }}
                  error={!!errors.area}
                  helperText={errors.area}
                  className="parcel-text-field"
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 3' } }}>
                <FormControl fullWidth className="parcel-select-field">
                  <InputLabel>Tipo de suelo</InputLabel>
                  <Select
                    name="soilType"
                    value={formData.soilType || ''}
                    label="Tipo de suelo"
                    onChange={handleChange}
                  >
                    {soilTypes.map(type => (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 3' } }}>
                <FormControl fullWidth className="parcel-select-field">
                  <InputLabel>Uso del suelo</InputLabel>
                  <Select
                    name="landUse"
                    value={formData.landUse || ''}
                    label="Uso del suelo"
                    onChange={handleChange}
                  >
                    {landUses.map(use => (
                      <MenuItem key={use} value={use}>{use}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 3' } }}>
                <TextField
                  name="region"
                  label="Región"
                  value={formData.region || ''}
                  onChange={handleChange}
                  fullWidth
                  className="parcel-text-field"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded className="parcel-accordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className="parcel-accordion-summary">
            <Typography variant="h6" className="parcel-section-title">
              <LocationIcon className="parcel-section-icon" />
              Ubicación y Catastro
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="parcel-accordion-details">
            <Grid container spacing={2} className="parcel-form-grid">
              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 3' } }}>
                <TextField
                  name="province"
                  label="Provincia"
                  value={formData.province || ''}
                  onChange={handleChange}
                  fullWidth
                  className="parcel-text-field"
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 3' } }}>
                <TextField
                  name="municipality"
                  label="Municipio"
                  value={formData.municipality || ''}
                  onChange={handleChange}
                  fullWidth
                  className="parcel-text-field"
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 2' } }}>
                <TextField
                  name="aggregate"
                  label="Agregado"
                  value={formData.aggregate || ''}
                  onChange={handleChange}
                  fullWidth
                  className="parcel-text-field"
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 2' } }}>
                <TextField
                  name="zone"
                  label="Zona"
                  value={formData.zone || ''}
                  onChange={handleChange}
                  fullWidth
                  className="parcel-text-field"
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 1' } }}>
                <TextField
                  name="polygon"
                  label="Polígono"
                  value={formData.polygon || ''}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  className="parcel-text-field"
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 1' } }}>
                <TextField
                  name="parcelNumber"
                  label="Parcela"
                  value={formData.parcelNumber || ''}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  className="parcel-text-field"
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: 'span 12' }}>
                <TextField
                  name="coordinates"
                  label="Coordenadas"
                  value={formData.coordinates || ''}
                  onChange={handleChange}
                  fullWidth
                  helperText="Formato: lat,long"
                  className="parcel-text-field"
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>

              <Grid className="parcel-map-container" sx={{ gridColumn: 'span 12' }}>
                <Paper elevation={2} className="parcel-map-paper">
                  <Typography variant="subtitle2" gutterBottom className="parcel-map-title">
                    <MapIcon className="parcel-section-icon" />
                    Mapa de la parcela
                    {formData.cadastralReference && (
                      <Typography variant="caption" className="parcel-selected-info">
                        Parcela {formData.polygon || '?'}-{formData.parcelNumber || '?'} seleccionada
                      </Typography>
                    )}
                    {errors.geojson && (
                      <Typography variant="caption" className="parcel-error-message">
                        {errors.geojson}
                      </Typography>
                    )}
                  </Typography>
                  <Box className="parcel-map-wrapper">
                    <MapComponent
                      key={mapKey}
                      geojson={formData.geojson}
                      initialPosition={formData.initialPosition}
                      onChange={handleMapChange}
                      onRecintoSelected={handleRecintoSelected}
                      showInfoCard={showRecintoInfo}
                      height={350}
                      className="parcel-map-component"
                    />
                    {mapLoading && (
                      <Box className="parcel-map-loading">
                        <CircularProgress size={24} className="parcel-map-spinner" />
                        <Typography variant="body2" className="parcel-map-loading-text">
                          Obteniendo tu ubicación...
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion className="parcel-accordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className="parcel-accordion-summary">
            <Typography variant="h6" className="parcel-section-title">
              <CalendarIcon className="parcel-section-icon" />
              Fechas y Características
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="parcel-accordion-details">
            <Grid container spacing={2} className="parcel-form-grid">
              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 4' } }}>
                <TextField
                  name="flightDate"
                  label="Fecha de vuelo"
                  type="date"
                  value={formData.flightDate || ''}
                  onChange={handleChange}
                  fullWidth
                  className="parcel-text-field"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 4' } }}>
                <TextField
                  name="cadastralMappingDate"
                  label="Fecha cartografía"
                  type="date"
                  value={formData.cadastralMappingDate || ''}
                  onChange={handleChange}
                  fullWidth
                  className="parcel-text-field"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 4' } }}>
                <TextField
                  name="slopePercentage"
                  label="Pendiente (%)"
                  type="number"
                  value={formData.slopePercentage || 0}
                  onChange={handleChange}
                  fullWidth
                  className="parcel-text-field"
                  inputProps={{ min: 0, max: 100 }}
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 4' } }}>
                <TextField
                  name="altitude"
                  label="Altitud (m)"
                  type="number"
                  value={formData.altitude || 0}
                  onChange={handleChange}
                  fullWidth
                  className="parcel-text-field"
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: { xs: 'span 6', sm: 'span 4' } }}>
                <TextField
                  name="irrigationCoefficient"
                  label="Coef. Riego"
                  type="number"
                  step="0.1"
                  value={formData.irrigationCoefficient || 0}
                  onChange={handleChange}
                  fullWidth
                  className="parcel-text-field"
                  inputProps={{ min: 0, max: 1 }}
                />
              </Grid>

              <Grid className="parcel-form-field" sx={{ gridColumn: 'span 12' }}>
                <TextField
                  name="incidents"
                  label="Incidencias"
                  value={formData.incidents || ''}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={3}
                  className="parcel-text-field"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </DialogContent>

      <DialogActions className="parcel-modal-actions">
        <Button
          onClick={onClose}
          startIcon={<CancelIcon />}
          color="secondary"
          disabled={loading}
          variant="outlined"
          className="parcel-cancel-button"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
          color="primary"
          variant="contained"
          disabled={loading}
          className="parcel-save-button"
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ParcelFormModal);