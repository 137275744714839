import apiClient from './apiClient';

const UserAPI = {
  async fetchAllUsers() {
    try {
      const response = await apiClient.get('/users');
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async fetchUserByUsername(username) {
    if (!username || typeof username !== 'string') {
      throw new Error('Nombre de usuario no válido');
    }

    try {
      const response = await apiClient.get(`/users/${username}`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async createUser(userData) {
    if (!userData || typeof userData !== 'object') {
      throw new Error('Datos de usuario no válidos');
    }

    try {
      const response = await apiClient.post('/users/register', userData);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async updateUser(username, userData) {
    if (!username || !userData) {
      throw new Error('Datos de actualización no válidos');
    }

    try {
      const response = await apiClient.put(`/users/${username}`, userData);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async deleteUser(username) {
    if (!username) {
      throw new Error('Nombre de usuario no válido');
    }

    try {
      await apiClient.delete(`/users/${username}`);
      return true;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  // Métodos legacy (para compatibilidad)
  getUserImageUrl(username) {
    return `${apiClient.defaults.baseURL}/users/${username}/image?t=${Date.now()}`;
  },

  getUserAvatarUrl(username) {
    return `${apiClient.defaults.baseURL}/users/${username}/avatar?t=${Date.now()}`;
  },

  // Nuevos métodos con autenticación
  async fetchUserImage(username) {
      try {
        const response = await apiClient.get(`/users/${username}/image`, {
          responseType: 'blob',
          headers: {
            'Cache-Control': 'no-cache'
          }
        });
        return URL.createObjectURL(response.data);
      } catch (error) {
        this.handleImageError(error);
        return '/default-user.png';
      }
    },

  async fetchUserAvatar(username) {
      try {
        const response = await apiClient.get(`/users/${username}/avatar`, {
          responseType: 'blob',
          headers: {
            'Cache-Control': 'no-cache'
          }
        });
        return URL.createObjectURL(response.data);
      } catch (error) {
        this.handleImageError(error);
        return '/default-avatar.png'; // Avatar por defecto
      }
    },

  async uploadUserImage(username, imageFile) {
    if (!username || !imageFile || !(imageFile instanceof File)) {
      throw new Error('Parámetros no válidos para subir imagen');
    }

    try {
      const formData = new FormData();
      formData.append('image', imageFile);

      const response = await apiClient.put(
        `/users/${username}/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async uploadUserAvatar(username, avatarFile) {
    if (!username || !avatarFile || !(avatarFile instanceof File)) {
      throw new Error('Parámetros no válidos para subir avatar');
    }

    try {
      const formData = new FormData();
      formData.append('avatar', avatarFile);

      const response = await apiClient.put(
        `/users/${username}/avatar`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async deleteUserImage(username) {
    if (!username) {
      throw new Error('Nombre de usuario no válido');
    }

    try {
      await apiClient.delete(`/users/${username}/image`);
      return true;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async deleteUserAvatar(username) {
    if (!username) {
      throw new Error('Nombre de usuario no válido');
    }

    try {
      await apiClient.delete(`/users/${username}/avatar`);
      return true;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  async getUserExploitations(username) {
    if (!username) {
      throw new Error('Nombre de usuario no válido');
    }

    try {
      const response = await apiClient.get(`/users/${username}/exploitations`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  },

  handleError(error) {
    let errorMessage = 'Error al procesar la solicitud';
    let errorDetails = null;
    let statusCode = null;

    if (error.response) {
      statusCode = error.response.status;
      errorMessage = error.response.data?.message ||
                   error.response.data?.error ||
                   `Error ${statusCode}: ${error.response.statusText}`;

      if (error.response.data?.errors) {
        errorDetails = error.response.data.errors;
      }
    } else if (error.request) {
      errorMessage = 'El servidor no respondió';
    } else {
      errorMessage = error.message || errorMessage;
    }

    const formattedError = new Error(errorMessage);
    formattedError.details = errorDetails;
    formattedError.status = statusCode;
    formattedError.isAuthError = statusCode === 401 || statusCode === 403;

    return formattedError;
  }
};

export default UserAPI;