import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Paper,
  TablePagination,
  LinearProgress,
  IconButton,
  Tooltip,
  Chip,
  TableSortLabel
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Map as MapIcon } from '@mui/icons-material';

const ParcelTable = ({
  parcels,
  loading,
  order,
  orderBy,
  page,
  rowsPerPage,
  totalParcels,
  onSort,
  onChangePage,
  onChangeRowsPerPage,
  onEdit,
  onDelete
}) => {
  const columns = [
    { id: 'name', label: 'Nombre' },
    { id: 'cadastralReference', label: 'Ref. Catastral' },
    { id: 'municipality', label: 'Municipio' },
    { id: 'area', label: 'Área (ha)', align: 'right' },
    { id: 'landUse', label: 'Uso' },
    { id: 'actions', label: 'Acciones', sortable: false, align: 'center' }
  ];

  return (
    <>
      <TableContainer component={Paper}>
        {loading && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align={column.align || 'left'}>
                  {column.sortable === false ? (
                    column.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => onSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && parcels.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  Cargando parcelas...
                </TableCell>
              </TableRow>
            )}

            {!loading && parcels.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No se encontraron parcelas
                </TableCell>
              </TableRow>
            )}

            {!loading && parcels.map(parcel => (
              <TableRow key={parcel.id} hover>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <MapIcon color="primary" sx={{ mr: 1 }} />
                    {parcel.name}
                  </Box>
                </TableCell>
                <TableCell>{parcel.cadastralReference}</TableCell>
                <TableCell>
                  {parcel.municipality}, {parcel.province}
                </TableCell>
                <TableCell align="right">{parcel.area}</TableCell>
                <TableCell>
                  <Chip
                    label={parcel.landUse}
                    size="small"
                    color={parcel.landUse.includes('Olivar') ? 'primary' : 'default'}
                  />
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Editar">
                    <IconButton onClick={() => onEdit(parcel)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton onClick={() => onDelete(parcel)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalParcels}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelRowsPerPage="Filas por página:"
      />
    </>
  );
};

export default ParcelTable;