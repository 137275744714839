import React, { useState, useEffect } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  TextField,
} from '@mui/material'
import { Map as MapIcon, Add as AddIcon } from '@mui/icons-material'
import ParcelTable from './components/ParcelTable'
import ParcelFormModal from './components/ParcelFormModal'
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal'
import ParcelAPI from '../../services/parcelAPI'

const INITIAL_PARCEL_STATE = {
  id: null,
  name: '',
  soilType: '',
  coordinates: '',
  geojson: '',
  area: 0,
  flightDate: new Date().toISOString().split('T')[0],
  cadastralMappingDate: '',
  province: '',
  municipality: '',
  aggregate: '',
  zone: '',
  polygon: 0,
  parcelNumber: 0,
  cadastralReference: '',
  slopePercentage: 0,
  altitude: 0,
  landUse: '',
  irrigationCoefficient: 0,
  incidents: '',
  region: '',
}

const ParcelWidget = () => {
  const [parcels, setParcels] = useState([])
  const [loading, setLoading] = useState({
    table: true,
    form: false,
    delete: false,
  })
  const [error, setError] = useState(null)
  const [currentParcel, setCurrentParcel] = useState(INITIAL_PARCEL_STATE)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [searchTerm, setSearchTerm] = useState('')
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  // Cargar parcelas
  useEffect(() => {
    let isMounted = true

    const loadParcels = async () => {
      try {
        setLoading((prev) => ({ ...prev, table: true }))
        const data = await ParcelAPI.fetchAllParcels()
        if (isMounted) {
          setParcels(data)
          console.log('Parcelas cargadas:', data) // Debug
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message)
          console.error('Error cargando parcelas:', err) // Debug
        }
      } finally {
        if (isMounted) {
          setLoading((prev) => ({ ...prev, table: false }))
        }
      }
    }

    loadParcels()

    return () => {
      isMounted = false
    }
  }, [])

  // Funciones de manejo de tabla
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase())
    setPage(0)
  }

  const handleChangePage = (_, newPage) => setPage(newPage)

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  // Funciones de manejo de parcelas
  const handleNewParcel = () => {
    console.log('Inicializando nueva parcela') // Debug
    setCurrentParcel(INITIAL_PARCEL_STATE)
    setEditModalOpen(true)
    setError(null)
  }

  const handleEditOpen = (parcel) => {
    console.log('Parcela seleccionada para edición:', parcel) // Debug

    // Asegurarse que todos los campos estén presentes
    const completeParcel = {
      ...INITIAL_PARCEL_STATE,
      ...parcel,
      flightDate: parcel.flightDate || new Date().toISOString().split('T')[0],
    }

    setCurrentParcel(completeParcel)
    setEditModalOpen(true)
    setError(null)

    console.log('CurrentParcel actualizado:', completeParcel) // Debug
  }

  const handleDeleteClick = (parcel) => {
    setCurrentParcel(parcel)
    setDeleteModalOpen(true)
    setError(null)
  }

  const handleDeleteConfirm = async () => {
    try {
      setLoading((prev) => ({ ...prev, delete: true }))
      await ParcelAPI.deleteParcel(currentParcel.id)
      const updatedParcels = await ParcelAPI.fetchAllParcels()
      setParcels(updatedParcels)
      setDeleteModalOpen(false)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading((prev) => ({ ...prev, delete: false }))
    }
  }

  const saveParcel = async (parcelData) => {
    try {
      setLoading((prev) => ({ ...prev, form: true }))
      console.log('Guardando parcela:', parcelData) // Debug

      const savedParcel = parcelData.id
        ? await ParcelAPI.updateParcel(parcelData.id, parcelData)
        : await ParcelAPI.createParcel(parcelData)

      const updatedParcels = await ParcelAPI.fetchAllParcels()
      setParcels(updatedParcels)
      setEditModalOpen(false)

      console.log('Parcela guardada exitosamente:', savedParcel) // Debug
    } catch (err) {
      console.error('Error guardando parcela:', err) // Debug
      setError(err.message)
      throw err
    } finally {
      setLoading((prev) => ({ ...prev, form: false }))
    }
  }

  const handleCloseModal = () => {
    setEditModalOpen(false)
    setCurrentParcel(INITIAL_PARCEL_STATE)
  }

  // Filtrado y ordenación
  const filteredParcels = parcels.filter(
    (parcel) =>
      parcel.name.toLowerCase().includes(searchTerm) ||
      parcel.cadastralReference?.toLowerCase().includes(searchTerm) ||
      parcel.municipality?.toLowerCase().includes(searchTerm),
  )

  const sortedParcels = [...filteredParcels].sort((a, b) => {
    const aValue = a[orderBy] || ''
    const bValue = b[orderBy] || ''
    return order === 'asc'
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue)
  })

  const paginatedParcels = sortedParcels.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  )

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '14px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
      }}
    >
      <CardContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          p: 3,
          '&:last-child': { pb: 3 },
        }}
      >
        {/* Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
          pb={2}
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <Typography
            variant="h5"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 600,
              color: 'primary.main',
            }}
          >
            <MapIcon sx={{ mr: 1.5, fontSize: '1.75rem' }} />
            Gestión de Parcelas
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNewParcel}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              px: 2,
              py: 1,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'primary.dark',
              },
            }}
          >
            Nueva Parcela
          </Button>
        </Box>

        {/* Error message */}
        {error && (
          <Typography
            color="error"
            sx={{
              mb: 2,
              backgroundColor: 'error.light',
              p: 1.5,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {error}
          </Typography>
        )}

        {/* Search field */}
        <TextField
          label="Buscar parcelas"
          variant="outlined"
          size="small"
          fullWidth
          sx={{
            mb: 3,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: 'divider',
              },
              '&:hover fieldset': {
                borderColor: 'primary.light',
              },
            },
          }}
          value={searchTerm}
          onChange={handleSearch}
          disabled={loading.table}
        />

        {/* Table container */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
          }}
        >
          <ParcelTable
            parcels={paginatedParcels}
            loading={loading.table}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            totalParcels={filteredParcels.length}
            onSort={handleSort}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onEdit={handleEditOpen} // Asegúrate que esto está bien conectado
            onDelete={handleDeleteClick}
          />
        </Box>

        {/* Modals */}
        <ParcelFormModal
          open={editModalOpen}
          onClose={handleCloseModal}
          parcel={currentParcel} // Se pasa el estado actualizado
          onSave={saveParcel}
          loading={loading.form}
          isNew={!currentParcel.id}
        />

        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirm}
          itemName={currentParcel?.name}
          loading={loading.delete}
        />
      </CardContent>
    </Card>
  )
}

export default ParcelWidget
