import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import SectionsWall from '../components/SectionsWall'
import { Box, Typography, CircularProgress } from '@mui/material'

const DashboardPage = () => {
  const navigate = useNavigate()
  const { isAuthenticated, loading: authLoading } =
    React.useContext(AuthContext)

  useEffect(() => {
    if (!authLoading && !isAuthenticated) {
      navigate('/login', { replace: true })
    }
  }, [isAuthenticated, authLoading, navigate])

  if (authLoading || !isAuthenticated) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={60} />
      </Box>
    )
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Panel de Control
      </Typography>
      <SectionsWall />
    </>
  )
}

export default DashboardPage
