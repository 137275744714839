import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  TextField
} from '@mui/material';
import { Business as BusinessIcon, Add as AddIcon } from '@mui/icons-material';
import ExploitationTable from './components/ExploitationTable';
import ExploitationFormModal from './components/ExploitationFormModal';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import ExploitationAPI from '../../services/exploitationAPI';

const INITIAL_EXPLOITATION_STATE = {
  name: '',
  nif: '',
  nationalRegistryNumber: '',
  regionalRegistryNumber: '',
  address: '',
  city: '',
  postalCode: '',
  province: '',
  landline: '',
  mobilePhone: '',
  totalArea: 0,
  startDate: new Date().toISOString().split('T')[0]
};

const ExploitationsWidget = () => {
  const [exploitations, setExploitations] = useState([]);
  const [loading, setLoading] = useState({
    table: true,
    form: false,
    delete: false
  });
  const [error, setError] = useState(null);
  const [currentExploitation, setCurrentExploitation] = useState(INITIAL_EXPLOITATION_STATE);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [searchTerm, setSearchTerm] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Cargar explotaciones con limpieza
  useEffect(() => {
    let isMounted = true;

    const loadExploitations = async () => {
      try {
        setLoading(prev => ({ ...prev, table: true }));
        const data = await ExploitationAPI.fetchAllExploitations();
        if (isMounted) {
          setExploitations(data);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(prev => ({ ...prev, table: false }));
        }
      }
    };

    loadExploitations();

    return () => {
      isMounted = false;
    };
  }, []);

  // Funciones de manejo
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPage(0);
  };

  const handleChangePage = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleNewExploitation = () => {
    setCurrentExploitation(INITIAL_EXPLOITATION_STATE);
    setEditModalOpen(true);
    setError(null);
  };

  const handleEditOpen = (exploitation) => {
    setCurrentExploitation(exploitation);
    setEditModalOpen(true);
    setError(null);
  };

  const handleDeleteClick = (exploitation) => {
    setCurrentExploitation(exploitation);
    setDeleteModalOpen(true);
    setError(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(prev => ({ ...prev, delete: true }));
      await ExploitationAPI.deleteExploitation(currentExploitation.id);
      const updatedExploitations = await ExploitationAPI.fetchAllExploitations();
      setExploitations(updatedExploitations);
      setDeleteModalOpen(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(prev => ({ ...prev, delete: false }));
    }
  };

  const saveExploitation = async (exploitationData) => {
    try {
      setLoading(prev => ({ ...prev, form: true }));
      if (exploitationData.id) {
        await ExploitationAPI.updateExploitation(exploitationData.id, exploitationData);
      } else {
        await ExploitationAPI.createExploitation(exploitationData);
      }
      const updatedExploitations = await ExploitationAPI.fetchAllExploitations();
      setExploitations(updatedExploitations);
      setEditModalOpen(false);
    } catch (err) {
      setError(err.message);
      throw err; // Re-lanzar para manejo en el formulario
    } finally {
      setLoading(prev => ({ ...prev, form: false }));
    }
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    setCurrentExploitation(INITIAL_EXPLOITATION_STATE);
  };

  // Filtrado y ordenación
  const filteredExploitations = exploitations.filter(exploitation =>
    exploitation.name.toLowerCase().includes(searchTerm) ||
    exploitation.nif?.toLowerCase().includes(searchTerm) ||
    exploitation.city?.toLowerCase().includes(searchTerm)
  );

  const sortedExploitations = [...filteredExploitations].sort((a, b) => {
    const aValue = a[orderBy] || '';
    const bValue = b[orderBy] || '';
    return order === 'asc'
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });

  const paginatedExploitations = sortedExploitations.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Card sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '14px',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)'
    }}>
      <CardContent sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        '&:last-child': { pb: 3 }
      }}>
        {/* Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
          pb={2}
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <Typography
            variant="h5"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 600,
              color: 'primary.main'
            }}
          >
            <BusinessIcon sx={{ mr: 1.5, fontSize: '1.75rem' }} />
            Gestión de Explotaciones
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNewExploitation}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              px: 2,
              py: 1,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'primary.dark'
              }
            }}
          >
            Nueva Explotación
          </Button>
        </Box>

        {/* Error message */}
        {error && (
          <Typography
            color="error"
            sx={{
              mb: 2,
              backgroundColor: 'error.light',
              p: 1.5,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {error}
          </Typography>
        )}

        {/* Search field */}
        <TextField
          label="Buscar explotaciones"
          variant="outlined"
          size="small"
          fullWidth
          sx={{
            mb: 3,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: 'divider'
              },
              '&:hover fieldset': {
                borderColor: 'primary.light'
              }
            }
          }}
          value={searchTerm}
          onChange={handleSearch}
          disabled={loading.table}
        />

        {/* Table container */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
          <ExploitationTable
            exploitations={paginatedExploitations}
            loading={loading.table}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            totalExploitations={filteredExploitations.length}
            onSort={handleSort}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onEdit={handleEditOpen}
            onDelete={handleDeleteClick}
          />
        </Box>

        {/* Modals */}
        <ExploitationFormModal
          open={editModalOpen}
          onClose={handleCloseModal}
          exploitation={currentExploitation}
          onSave={saveExploitation}
          loading={loading.form}
          isNew={!currentExploitation.id}
        />

        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirm}
          itemName={currentExploitation?.name}
          loading={loading.delete}
        />
      </CardContent>
    </Card>
  );
};

export default ExploitationsWidget;