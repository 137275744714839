import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useNavigate, Link } from 'react-router-dom'
import {
  Container,
  Box,
  Typography,
  Snackbar,
  Alert,
  Button,
  CircularProgress,
} from '@mui/material'
import LoginWidget from '../features/login/LoginWidget'
import logo from '../assets/logo.png'
import '../features/login/LoginWidget.css'

const LoginPage = () => {
  const navigate = useNavigate()
  const {
    handleLogin,
    isAuthenticated,
    loading: authLoading,
  } = useContext(AuthContext)

  const [loginError, setLoginError] = useState(null)
  const [formLoading, setFormLoading] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [emailForResend, setEmailForResend] = useState('')

  // Redirige si ya está autenticado
  useEffect(() => {
    if (!authLoading && isAuthenticated) {
      navigate('/dashboard', { replace: true })
    }
  }, [isAuthenticated, authLoading, navigate])

  // Muestra spinner mientras verifica autenticación
  if (authLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={60} />
      </Box>
    )
  }

  // No renderiza nada si está autenticado (será redirigido)
  if (isAuthenticated) {
    return null
  }

  const classifyError = (error) => {
    if (!error) return 'generic'

    const apiError = error.response?.data?.error || error.message || ''

    if (apiError.includes('Cuenta no verificada')) return 'unverified'
    if (apiError.includes('bloqueada') || apiError.includes('locked'))
      return 'locked'
    if (error.response?.status === 429) return 'rateLimited'
    if (error.response?.status === 401) return 'unauthorized'
    return 'generic'
  }

  const getFriendlyErrorMessage = (error) => {
    if (!error) return 'Error desconocido'

    // Extraer mensaje de diferentes formatos de error
    const serverMessage =
      error.response?.data?.error ||
      error.response?.data?.message ||
      error.message ||
      error.toString()

    // Mapeo de mensajes específicos
    const errorMap = {
      'Usuario o contraseña incorrectos': 'Usuario o contraseña incorrectos',
      invalid_credentials: 'Usuario o contraseña incorrectos',
      'Cuenta no verificada': 'Por favor verifica tu correo electrónico',
      account_locked: 'Cuenta bloqueada temporalmente',
      invalid_csrf: 'Error de seguridad. Recarga la página',
      'Network Error': 'Error de conexión con el servidor',
      'Failed to fetch': 'Error de conexión con el servidor',
    }

    // Buscar coincidencia exacta o parcial
    for (const [key, value] of Object.entries(errorMap)) {
      if (serverMessage.includes(key)) {
        return value
      }
    }

    // Mensajes por código de estado
    if (error.response?.status === 401) {
      return 'Usuario o contraseña incorrectos'
    }

    return 'Error al iniciar sesión. Por favor intente nuevamente.'
  }

  const handleLoginSubmit = async ({ username, password, remember }) => {
    setFormLoading(true)
    setLoginError(null)
    setEmailForResend(username)

    try {
      await handleLogin(username, password, remember) // Pasar el parámetro remember
      // La redirección se manejará automáticamente por el efecto
    } catch (error) {
      console.error('Error en login:', {
        error: error,
        response: error.response,
        message: error.message,
      })

      const errorMessage = getFriendlyErrorMessage(error)
      setLoginError({
        message: errorMessage,
        type: classifyError(error),
      })
      setSnackbarOpen(true)
    } finally {
      setFormLoading(false)
    }
  }

  const handleResendVerification = async () => {
    try {
      // Aquí iría la llamada a tu API para reenviar el email
      // await authService.resendVerificationEmail(emailForResend);

      setLoginError({
        message: 'Email de verificación enviado. Revise su bandeja de entrada.',
        type: 'success',
      })
      setSnackbarOpen(true)
    } catch (error) {
      setLoginError({
        message: 'Error al reenviar el email. Intente más tarde.',
        type: 'error',
      })
      setSnackbarOpen(true)
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const renderSnackbarAction = () => {
    if (!loginError?.type) return null

    const actions = {
      unverified: (
        <Button
          color="secondary"
          size="small"
          onClick={handleResendVerification}
          data-testid="resend-email-button"
        >
          Reenviar email
        </Button>
      ),
      locked: (
        <Button
          color="secondary"
          size="small"
          component={Link}
          to="/soporte"
          data-testid="contact-support-button"
        >
          Contactar soporte
        </Button>
      ),
      rateLimited: (
        <Button
          color="secondary"
          size="small"
          component={Link}
          to="/recuperar-contrasena"
          data-testid="recover-password-button"
        >
          Recuperar contraseña
        </Button>
      ),
    }

    return actions[loginError.type] || null
  }

  return (
    <Container
      maxWidth="sm"
      className="login-page-container"
      data-testid="login-page"
    >
      <Box className="login-page-inner-container">
        <LoginWidget
          onLogin={handleLoginSubmit}
          loading={formLoading}
          initialError={loginError?.message}
          showTitle={false}
          enableEmail={true}
          rememberMe={true}
          customHeader={
            <Box className="login-widget-header">
              <img
                src={logo}
                alt="Logo de la aplicación"
                className="login-page-logo"
                data-testid="login-logo"
              />
              <Typography
                variant="h4"
                className="login-page-title"
                data-testid="login-title"
              >
                Bienvenido
              </Typography>
              <Typography
                variant="body1"
                className="login-page-subtitle"
                data-testid="login-subtitle"
              >
                Ingrese sus credenciales para continuar
              </Typography>
            </Box>
          }
          customFooter={
            <Box className="login-widget-footer">
              <Typography variant="body2">
                ¿No tienes cuenta?{' '}
                <Link
                  to="/register"
                  className="login-page-legal-link"
                  data-testid="register-link"
                >
                  Regístrate
                </Link>
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                <Link
                  to="/recuperar-contrasena"
                  className="login-page-legal-link"
                  data-testid="forgot-password-link"
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </Typography>
            </Box>
          }
        />

        <Box className="login-page-legal">
          <Typography variant="caption">
            Al continuar aceptas nuestros{' '}
            <Link
              to="/terminos"
              className="login-page-legal-link"
              data-testid="terms-link"
            >
              Términos
            </Link>{' '}
            y{' '}
            <Link
              to="/privacidad"
              className="login-page-legal-link"
              data-testid="privacy-link"
            >
              Privacidad
            </Link>
          </Typography>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={loginError?.type === 'unverified' ? 10000 : 6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          data-testid="login-snackbar"
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={loginError?.type === 'success' ? 'success' : 'error'}
            action={renderSnackbarAction()}
            sx={{ width: '100%', alignItems: 'center' }}
            data-testid={`alert-${loginError?.type || 'error'}`}
          >
            {loginError?.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  )
}

export default LoginPage
