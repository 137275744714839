import React from 'react';
import SectionsWall from '../components/SectionsWall';

const DashboardPage = () => {
  return (
    <div>
      <SectionsWall />
    </div>
  );
};

export default DashboardPage;