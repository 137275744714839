import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import TileWMS from 'ol/source/TileWMS';
import GeoJSON from 'ol/format/GeoJSON';
import { fromLonLat } from 'ol/proj';
import { defaults as defaultInteractions } from 'ol/interaction';
import { defaults as defaultControls } from 'ol/control';

const MapComponent = ({
  geojson,
  onChange,
  height = 300,
  initialPosition = null, // Cambiado a null por defecto
  initialZoom = 6,
  showInfoCard,
  onRecintoSelected,
  className = ''
}) => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const vectorSource = useRef(new VectorSource());
  const [mapReady, setMapReady] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [loadingLocation, setLoadingLocation] = useState(false);

  // Capas base memoizadas
  const baseLayers = useMemo(() => ({
    ign: new TileLayer({
      source: new TileWMS({
        url: 'https://www.ign.es/wms-inspire/mapa-raster',
        params: {
          'LAYERS': 'mtn_rasterizado',
          'TILED': true,
          'FORMAT': 'image/png',
          'VERSION': '1.3.0'
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
      }),
      visible: true
    }),
    esri: new TileLayer({
      source: new XYZ({
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        attributions: 'Tiles © Esri',
        crossOrigin: 'anonymous'
      }),
      visible: false
    }),
    sigpac: new TileLayer({
      source: new TileWMS({
        url: 'https://wms.mapa.gob.es/sigpac/wms',
        params: {
          'LAYERS': 'AU.Sigpac:recinto',
          'TILED': true,
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0'
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
      }),
      opacity: 0.7,
      visible: false
    }),
    osm: new TileLayer({
      source: new OSM(),
      visible: false
    })
  }), []);

  // Obtener ubicación del usuario
  useEffect(() => {
    if (!initialPosition && !userLocation && !loadingLocation) {
      setLoadingLocation(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { longitude, latitude } = position.coords;
            setUserLocation([longitude, latitude]);
            setLoadingLocation(false);
          },
          (error) => {
            console.error('Error obteniendo ubicación:', error);
            setUserLocation([0, 0]); // Fallback a coordenadas por defecto
            setLoadingLocation(false);
          }
        );
      } else {
        console.warn('Geolocation no soportado por el navegador');
        setUserLocation([0, 0]); // Fallback a coordenadas por defecto
        setLoadingLocation(false);
      }
    }
  }, [initialPosition, userLocation, loadingLocation]);

  // Función para mostrar información del recinto
  const showRecintoInfo = useCallback((properties) => {
    const info = `Provincia: ${properties.provincia || 'Desconocido'}\nMunicipio: ${properties.municipio || 'Desconocido'}`;
    if (showInfoCard) {
      showInfoCard('Información del Recinto', info);
    }
  }, [showInfoCard]);

  // Ajustar capas según el zoom
  const adjustLayersBasedOnZoom = useCallback((zoom) => {
    if (!mapInstance.current) return;

    if (zoom >= 17.5) {
      baseLayers.ign.setVisible(false);
      baseLayers.esri.setVisible(true);
      baseLayers.sigpac.setVisible(true);
      baseLayers.osm.setVisible(false);
    } else if (zoom >= 14) {
      baseLayers.ign.setVisible(true);
      baseLayers.esri.setVisible(false);
      baseLayers.sigpac.setVisible(false);
      baseLayers.osm.setVisible(false);
    } else {
      baseLayers.ign.setVisible(false);
      baseLayers.esri.setVisible(false);
      baseLayers.sigpac.setVisible(false);
      baseLayers.osm.setVisible(true);
    }
  }, [baseLayers]);

  // Inicializar el mapa
  useEffect(() => {
    if (!mapRef.current || mapInstance.current) return;

    // Determinar la posición inicial
    const defaultPosition = initialPosition || userLocation || [0, 0];
    const center = fromLonLat(defaultPosition);

    const vectorLayer = new VectorLayer({
      source: vectorSource.current,
      style: {
        'fill-color': 'rgba(51, 136, 255, 0.2)',
        'stroke-color': '#3388ff',
        'stroke-width': 3
      }
    });

    // Configuración del mapa
    mapInstance.current = new Map({
      target: mapRef.current,
      layers: [
        baseLayers.osm,
        baseLayers.ign,
        baseLayers.esri,
        baseLayers.sigpac,
        vectorLayer
      ],
      view: new View({
        center,
        zoom: initialZoom,
        minZoom: 7,
        maxZoom: 20
      }),
      controls: defaultControls(),
      interactions: defaultInteractions()
    });

    // Evento de zoom
    const handleZoomChange = () => {
      const zoom = mapInstance.current.getView().getZoom();
      adjustLayersBasedOnZoom(zoom);
    };

    mapInstance.current.getView().on('change:resolution', handleZoomChange);

    // Evento de clic para SIGPAC
    const clickHandler = async (evt) => {
      const zoom = mapInstance.current.getView().getZoom();
      if (zoom >= 17.5 && baseLayers.sigpac.getVisible()) {
        const view = mapInstance.current.getView();
        try {
          const url = baseLayers.sigpac.getSource().getFeatureInfoUrl(
            evt.coordinate,
            view.getResolution(),
            view.getProjection(),
            { 'INFO_FORMAT': 'application/json' }
          );

          const response = await fetch(url);
          const data = await response.json();
          if (data.features?.length > 0) {
            const recintoInfo = data.features[0].properties;
            showRecintoInfo(recintoInfo);
            onRecintoSelected?.(recintoInfo);
          }
        } catch (error) {
          console.error('Error fetching SIGPAC data:', error);
        }
      }
    };

    mapInstance.current.on('singleclick', clickHandler);
    setMapReady(true);

    return () => {
      if (mapInstance.current) {
        mapInstance.current.getView().un('change:resolution', handleZoomChange);
        mapInstance.current.un('singleclick', clickHandler);
        mapInstance.current.setTarget(undefined);
        mapInstance.current = null;
      }
    };
  }, [initialPosition, initialZoom, userLocation]);

  // Cargar GeoJSON
  useEffect(() => {
    if (!mapReady || !geojson || !mapInstance.current) return;

    try {
      const parsedGeoJson = typeof geojson === 'string' ? JSON.parse(geojson) : geojson;
      const features = new GeoJSON().readFeatures(parsedGeoJson);
      vectorSource.current.clear();
      vectorSource.current.addFeatures(features);

      if (features.length > 0) {
        mapInstance.current.getView().fit(vectorSource.current.getExtent(), {
          padding: [50, 50, 50, 50],
          maxZoom: 18
        });
      }
    } catch (error) {
      console.error('Error parsing GeoJSON:', error);
    }
  }, [geojson, mapReady]);

  return (
    <div
      ref={mapRef}
      className={className}
      style={{
        height: `${height}px`,
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #e0e0e0',
        position: 'relative'
      }}
    >
      {loadingLocation && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255,255,255,0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <div>Cargando tu ubicación...</div>
        </div>
      )}
    </div>
  );
};

export default React.memo(MapComponent);