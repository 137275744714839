// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ExploitationFormModal.css */
.exploitation-form-section-title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 4px;
}

.exploitation-form-divider {
  margin-bottom: 24px !important;
}

.exploitation-form-actions {
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  justify-content: flex-end;
}

.exploitation-form-field {
  margin-bottom: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/features/exploitations/ExploitationsWidget.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,yCAAyC;EACzC,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["/* ExploitationFormModal.css */\r\n.exploitation-form-section-title {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 16px;\r\n  padding-bottom: 4px;\r\n}\r\n\r\n.exploitation-form-divider {\r\n  margin-bottom: 24px !important;\r\n}\r\n\r\n.exploitation-form-actions {\r\n  padding: 16px 24px;\r\n  border-top: 1px solid rgba(0, 0, 0, 0.12);\r\n  justify-content: flex-end;\r\n}\r\n\r\n.exploitation-form-field {\r\n  margin-bottom: 16px !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
