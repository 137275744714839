import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Typography,
  Avatar,
  CircularProgress
} from '@mui/material';
import { Save as SaveIcon, Cancel as CancelIcon, CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import UserAPI from '../../../services/userAPI';
import '../UsersWidget.css';

const INITIAL_FORM_STATE = {
  fullName: '',
  email: '',
  nif: '',
  address: '',
  city: '',
  postalCode: '',
  province: '',
  representationType: '',
  phone: '',
  role: 'USER'
};

const UserFormModal = ({ open, onClose, user, onSave, onImageUpload, onImageRemove, loading, isNew }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [imagePreview, setImagePreview] = useState('');
  const [avatarPreview, setAvatarPreview] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);

useEffect(() => {
    if (open) {
      if (isNew) {
        // Para nuevo usuario: resetear todo
        setFormData(INITIAL_FORM_STATE);
        setImagePreview('');
        setAvatarPreview('');
      } else {
        // Para edición: cargar datos existentes
        setFormData({
          fullName: user.fullName || '',
          email: user.email || '',
          nif: user.nif || '',
          address: user.address || '',
          city: user.city || '',
          postalCode: user.postalCode || '',
          province: user.province || '',
          representationType: user.representationType || '',
          phone: user.phone || '',
          role: user.role || 'USER'
        });

        // Cargar imágenes solo si existen
        setImagePreview(user.imageUrl ? UserAPI.getUserImageUrl(user.username) : '');
        setAvatarPreview(user.avatarUrl ? UserAPI.getUserAvatarUrl(user.username) : '');
      }
    }
  }, [open, user, isNew]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = async (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    const setUploading = type === 'avatar' ? setUploadingAvatar : setUploadingImage;
    const setPreview = type === 'avatar' ? setAvatarPreview : setImagePreview;

    try {
      setUploading(true);
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);

      await onImageUpload(user?.username || 'new-user', file, type);
    } catch (err) {
      setPreview('');
    } finally {
      setUploading(false);
    }
  };

  const handleRemove = async (type) => {
    if (!user?.username) return;

    try {
      await onImageRemove(user.username, type);
      type === 'avatar' ? setAvatarPreview('') : setImagePreview('');
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async () => {
    await onSave(user?.username, formData);
    onClose();
  };

  const handleImageError = (e, type) => {
    const name = user?.username || 'Usuario';
    e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`;
    e.target.onerror = null;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth className="user-form-modal">
      <DialogTitle className="user-form-modal-title">
        {user ? `Editar Usuario: ${user.username}` : 'Crear Nuevo Usuario'}
      </DialogTitle>
      <DialogContent className="user-form-modal-content">
        <div className="user-form-vertical-layout">
          {/* Sección de imagen principal (ancho completo) */}
          <Box className="user-form-modal-main-image-section">
            <Typography variant="subtitle1" className="user-form-modal-section-title">
              Imagen Principal del Perfil
            </Typography>
            <Box className="user-form-modal-main-image-container">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Imagen de perfil"
                  className="user-form-modal-main-image"
                  onError={(e) => handleImageError(e, 'profile')}
                />
              ) : (
                <Box className="user-form-modal-image-placeholder">
                  <Typography variant="body2" color="textSecondary">
                    Haz clic en "Subir imagen" para agregar una foto de perfil
                  </Typography>
                </Box>
              )}
            </Box>
            <Box className="user-form-modal-image-buttons">
              <Button
                variant="outlined"
                component="label"
                startIcon={<CloudUploadIcon />}
                disabled={uploadingImage}
                className="user-form-modal-image-button"
              >
                {imagePreview ? 'Cambiar imagen' : 'Subir imagen'}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, 'profile')}
                />
              </Button>
              {imagePreview && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleRemove('profile')}
                  disabled={uploadingImage}
                  className="user-form-modal-image-button"
                >
                  Eliminar imagen
                </Button>
              )}
            </Box>
          </Box>

        {/* Sección combinada: Avatar + Información Básica */}
        <Box className="user-form-combined-section">
          {/* Sección de avatar (ahora en paralelo) */}
          <Box className="user-form-modal-avatar-section combined-item">
            <Typography variant="subtitle1" className="user-form-modal-section-title">
              Avatar
            </Typography>
            <Box className="user-form-modal-avatar-container">
              {avatarPreview ? (
                <Avatar
                  src={avatarPreview}
                  className="user-form-modal-avatar-image"
                  onError={(e) => handleImageError(e, 'avatar')}
                />
              ) : (
                <Avatar
                  src={user?.username ? UserAPI.getUserAvatarUrl(user.username) : ''}
                  className="user-form-modal-avatar-image"
                  onError={(e) => handleImageError(e, 'avatar')}
                />
              )}
            </Box>
            <Box className="user-form-modal-image-buttons">
              <Button
                variant="outlined"
                component="label"
                startIcon={<CloudUploadIcon />}
                disabled={uploadingAvatar}
                className="user-form-modal-image-button"
              >
                Cambiar avatar
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, 'avatar')}
                />
              </Button>
              {avatarPreview && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleRemove('avatar')}
                  disabled={uploadingAvatar}
                  className="user-form-modal-image-button"
                >
                  Eliminar avatar
                </Button>
              )}
            </Box>
          </Box>

          {/* Sección de información básica (ahora en paralelo) */}
          <Box className="user-form-modal-data-section combined-item">
            <Typography variant="subtitle1" className="user-form-modal-section-title">
              Información Básica
            </Typography>
            <Grid container spacing={2} className="user-form-modal-grid">
              <Grid item xs={12} md={6}>
                <TextField
                  label="Nombre completo"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  className="user-form-modal-field"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  className="user-form-modal-field"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="NIF/NIE"
                  name="nif"
                  value={formData.nif}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="user-form-modal-field"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small" className="user-form-modal-field">
                  <InputLabel>Rol</InputLabel>
                  <Select
                    name="role"
                    value={formData.role}
                    label="Rol"
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="USER">Usuario</MenuItem>
                    <MenuItem value="ADMIN">Administrador</MenuItem>
                    <MenuItem value="MANAGER">Gestor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>

          {/* Sección de datos de contacto (ancho completo) */}
          <Box className="user-form-modal-data-section">
            <Typography variant="subtitle1" className="user-form-modal-section-title">
              Datos de Contacto
            </Typography>
            <Grid container spacing={2} className="user-form-modal-grid">
              <Grid item xs={12}>
                <TextField
                  label="Dirección"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  size="small"
                  className="user-form-modal-field"
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  label="Ciudad"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="user-form-modal-field"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Código Postal"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="user-form-modal-field"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Provincia"
                  name="province"
                  value={formData.province}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="user-form-modal-field"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Teléfono"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="user-form-modal-field"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small" className="user-form-modal-field">
                  <InputLabel>Tipo Representación</InputLabel>
                  <Select
                    name="representationType"
                    value={formData.representationType}
                    label="Tipo Representación"
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="FISICA">Persona Física</MenuItem>
                    <MenuItem value="JURIDICA">Persona Jurídica</MenuItem>
                    <MenuItem value="AUTONOMO">Autónomo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </div>
      </DialogContent>
      <DialogActions className="user-form-modal-actions">
        <Button
          onClick={onClose}
          startIcon={<CancelIcon />}
          className="user-form-modal-action-button"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
          disabled={loading}
          className="user-form-modal-action-button"
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserFormModal;