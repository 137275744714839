import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { authService } from '../services/authAPI';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [tokens, setTokens] = useState(() => ({
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken')
  }));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateUserFromToken = useCallback((token) => {
    if (!token) {
      setUser(null);
      return;
    }

    try {
      const decoded = jwtDecode(token);
      setUser({
        id: decoded.sub || decoded.userId,
        username: decoded.username,
        email: decoded.email,
        role: decoded.roles?.[0]?.replace('ROLE_', '') || 'user', // Adaptado para tu estructura JWT
      });
    } catch (error) {
      console.error('Error decodificando token:', error);
      setUser(null);
    }
  }, []);

  const handleLogin = async (username, password) => {
    setLoading(true);
    setError(null);

    try {
      const data = await authService.login(username, password);

      if (!data?.accessToken) {
        throw new Error('No se recibieron tokens válidos');
      }

      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      setTokens(data);
      updateUserFromToken(data.accessToken);

      return true; // Indicar éxito
    } catch (err) {
      const errorMessage = typeof err === 'string' ? err : err.message;
      setError(errorMessage || 'Error de autenticación');
      throw errorMessage; // Lanzar string en lugar de objeto Error
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = useCallback(async () => {
    try {
      if (tokens.accessToken) {
        await authService.revokeToken(tokens.accessToken);
      }
    } catch (err) {
      console.error('Error al revocar token:', err);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setTokens({ accessToken: null, refreshToken: null });
      setUser(null);
      setLoading(false);
    }
  }, [tokens.accessToken]);

  // Verificación inicial al montar el componente
  useEffect(() => {
    let isMounted = true;

    const verifyAuthState = async () => {
      try {
        if (tokens.accessToken) {
          const isValid = await authService.verifyToken(tokens.accessToken);
          if (!isValid && isMounted) {
            await handleLogout();
            return;
          }
          updateUserFromToken(tokens.accessToken);
        }
      } catch (err) {
        console.error('Error verificando autenticación:', err);
        if (isMounted) await handleLogout();
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    verifyAuthState();

    return () => {
      isMounted = false;
    };
  }, [tokens.accessToken, handleLogout, updateUserFromToken]);

  // Interceptor para manejar tokens expirados
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 &&
            !originalRequest._retry &&
            tokens.refreshToken) {

          originalRequest._retry = true;

          try {
            const data = await authService.refreshToken(tokens.refreshToken);

            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
            setTokens(data);
            updateUserFromToken(data.accessToken);

            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
            return axios(originalRequest);
          } catch (refreshError) {
            await handleLogout();
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [tokens.refreshToken, handleLogout, updateUserFromToken]);

  return (
    <AuthContext.Provider value={{
      user,
      tokens,
      loading,
      error,
      handleLogin,
      handleLogout,
      isAuthenticated: !!user && !!tokens.accessToken,
      updateUserFromToken
    }}>
      {children}
    </AuthContext.Provider>
  );
};