import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Alert,
  CircularProgress
} from '@mui/material';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { handleLogin, loading: authLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // Validación básica
    if (!username.trim() || !password.trim()) {
      setError('Usuario y contraseña son requeridos');
      return;
    }

    try {
      setLoading(true);
      await handleLogin(username, password);
      navigate('/dashboard');
    } catch (err) {
      // Asegurarnos de mostrar solo el mensaje
      const errorMessage = typeof err === 'string'
        ? err
        : err?.message || 'Error al iniciar sesión';

      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{ color: 'primary.main' }}
        >
          Iniciar Sesión
        </Typography>

        <Box
          component="form"
          onSubmit={onSubmit}
          sx={{ mt: 2 }}
          noValidate
        >
          <TextField
            label="Usuario"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="username"
            error={!!error}
            disabled={loading || authLoading}
          />

          <TextField
            label="Contraseña"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            error={!!error}
            disabled={loading || authLoading}
          />

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            disabled={loading || authLoading}
            startIcon={
              (loading || authLoading) &&
              <CircularProgress size={20} color="inherit" />
            }
          >
            {loading || authLoading ? 'Ingresando...' : 'Ingresar'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPage;