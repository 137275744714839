import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  TextField
} from '@mui/material';
import { People as PeopleIcon, Add as AddIcon } from '@mui/icons-material';
import UserTable from './components/UserTable';
import UserFormModal from './components/UserFormModal';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import UserAPI from '../../services/userAPI';

const INITIAL_USER_STATE = {
  username: '',
  fullName: '',
  email: '',
  nif: '',
  address: '',
  city: '',
  postalCode: '',
  province: '',
  representationType: '',
  phone: '',
  role: 'USER',
  imageUrl: '',
  avatarUrl: ''
};

const UsersWidget = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(INITIAL_USER_STATE);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('username');
  const [searchTerm, setSearchTerm] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Cargar usuarios
  useEffect(() => {
    let isMounted = true;

    const fetchUsers = async () => {
      try {
        setLoading(true);
        const data = await UserAPI.fetchAllUsers();
        if (isMounted) {
          setUsers(data);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchUsers();

    return () => {
      isMounted = false;
    };
  }, []);

  // Funciones de manejo de la tabla
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPage(0);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  // Funciones de manejo de usuarios
  const handleNewUser = () => {
    setCurrentUser(INITIAL_USER_STATE);
    setEditModalOpen(true);
    setError(null);
  };

  const handleEditOpen = (user) => {
    setCurrentUser(user);
    setEditModalOpen(true);
    setError(null);
  };

  const handleDeleteClick = (user) => {
    setCurrentUser(user);
    setDeleteModalOpen(true);
    setError(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      await UserAPI.deleteUser(currentUser.username);
      const updatedUsers = await UserAPI.fetchAllUsers();
      setUsers(updatedUsers);
      setDeleteModalOpen(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (username, userData) => {
    try {
      setLoading(true);
      await UserAPI.updateUser(username, userData);
      const updatedUsers = await UserAPI.fetchAllUsers();
      setUsers(updatedUsers);
      setEditModalOpen(false);
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const uploadImage = async (username, file, type) => {
    try {
      return type === 'avatar'
        ? await UserAPI.uploadUserAvatar(username, file)
        : await UserAPI.uploadUserImage(username, file);
    } catch (err) {
      setError(`Error al subir ${type === 'avatar' ? 'el avatar' : 'la imagen'}: ${err.message}`);
      throw err;
    }
  };

  const removeImage = async (username, type) => {
    try {
      return type === 'avatar'
        ? await UserAPI.deleteUserAvatar(username)
        : await UserAPI.deleteUserImage(username);
    } catch (err) {
      setError(`Error al eliminar ${type === 'avatar' ? 'el avatar' : 'la imagen'}: ${err.message}`);
      throw err;
    }
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    setCurrentUser(INITIAL_USER_STATE);
  };

  // Filtrado y ordenación
  const filteredUsers = users.filter(user =>
    user.username.toLowerCase().includes(searchTerm) ||
    (user.fullName?.toLowerCase() || '').includes(searchTerm) ||
    user.email?.toLowerCase().includes(searchTerm)
  );

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    const aValue = a[orderBy] || '';
    const bValue = b[orderBy] || '';
    return order === 'asc'
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });

  const paginatedUsers = sortedUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Card sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '14px',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)'
    }}>
      <CardContent sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        '&:last-child': { pb: 3 }
      }}>
        {/* Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
          pb={2}
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <Typography
            variant="h5"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 600,
              color: 'primary.main'
            }}
          >
            <PeopleIcon sx={{ mr: 1.5, fontSize: '1.75rem' }} />
            Gestión de Usuarios
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNewUser}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              px: 2,
              py: 1,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'primary.dark'
              }
            }}
          >
            Nuevo Usuario
          </Button>
        </Box>

        {/* Error message */}
        {error && (
          <Typography
            color="error"
            sx={{
              mb: 2,
              backgroundColor: 'error.light',
              p: 1.5,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {error}
          </Typography>
        )}

        {/* Search field */}
        <TextField
          label="Buscar usuarios"
          variant="outlined"
          size="small"
          fullWidth
          sx={{
            mb: 3,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: 'divider'
              },
              '&:hover fieldset': {
                borderColor: 'primary.light'
              }
            }
          }}
          value={searchTerm}
          onChange={handleSearch}
        />

        {/* Table container */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
          <UserTable
            users={paginatedUsers}
            loading={loading}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            totalUsers={filteredUsers.length}
            onSort={handleSort}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onEdit={handleEditOpen}
            onDelete={handleDeleteClick}
          />
        </Box>

        {/* Modals */}
        <UserFormModal
          open={editModalOpen}
          onClose={handleCloseModal}
          user={currentUser}
          onSave={updateUser}
          onImageUpload={uploadImage}
          onImageRemove={removeImage}
          loading={loading}
          isNew={!currentUser.username}
        />

        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirm}
          itemName={currentUser?.username}
          loading={loading}
        />
      </CardContent>
    </Card>
  );
};

export default UsersWidget;