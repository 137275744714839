import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { authService } from '../services/authAPI'

export const AuthContext = createContext()

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth debe usarse dentro de un AuthProvider')
  }
  return context
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [tokens, setTokens] = useState(() => {
    if (typeof window !== 'undefined') {
      // Intentar cargar de localStorage (sesión persistente)
      const persistentTokens = {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
      }

      // Si no hay tokens persistentes, verificar sessionStorage (sesión temporal)
      if (!persistentTokens.accessToken) {
        return {
          accessToken: sessionStorage.getItem('accessToken'),
          refreshToken: sessionStorage.getItem('refreshToken'),
        }
      }
      return persistentTokens
    }
    return { accessToken: null, refreshToken: null }
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [rememberMe, setRememberMe] = useState(() => {
    // Recuperar preferencia de "Recordar mi sesión" del localStorage
    return localStorage.getItem('rememberMe') === 'true'
  })

  const updateUserFromToken = useCallback((token) => {
    if (!token) {
      setUser(null)
      return
    }

    try {
      const decoded = jwtDecode(token)
      setUser({
        id: decoded.sub || decoded.userId,
        username: decoded.username,
        email: decoded.email,
        role: decoded.roles?.[0]?.replace('ROLE_', '') || 'user',
        expiresAt: decoded.exp ? new Date(decoded.exp * 1000) : null,
      })
    } catch (error) {
      console.error('Error decodificando token:', error)
      setUser(null)
    }
  }, [])

  const storeTokens = useCallback(({ accessToken, refreshToken }, remember) => {
    if (remember) {
      // Almacenar en localStorage para persistencia
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('rememberMe', 'true')
    } else {
      // Almacenar en sessionStorage (se pierde al cerrar el navegador)
      sessionStorage.setItem('accessToken', accessToken)
      sessionStorage.setItem('refreshToken', refreshToken)
      localStorage.removeItem('rememberMe')
    }
    setRememberMe(remember)
    setTokens({ accessToken, refreshToken })
  }, [])

  const clearTokens = useCallback(() => {
    // Limpiar todos los almacenamientos
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('rememberMe')
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('refreshToken')
    setTokens({ accessToken: null, refreshToken: null })
    setRememberMe(false)
  }, [])

  const handleLogin = async (username, password, remember = false) => {
    setLoading(true)
    setError(null)

    try {
      const data = await authService.login(username, password)

      if (!data?.accessToken) {
        throw new Error('No se recibieron tokens válidos')
      }

      storeTokens(data, remember)
      updateUserFromToken(data.accessToken)

      return true
    } catch (err) {
      const errorMessage =
        err.response?.data?.error || err.message || 'Error de autenticación'
      setError(errorMessage)
      throw errorMessage
    } finally {
      setLoading(false)
    }
  }

  const handleLogout = useCallback(async () => {
    try {
      if (tokens.accessToken) {
        await authService.revokeToken(tokens.accessToken)
      }
    } catch (err) {
      console.error('Error al revocar token:', err)
    } finally {
      clearTokens()
      setUser(null)
      setError(null)
      setLoading(false)
    }
  }, [tokens.accessToken, clearTokens])

  // Función para verificar el estado de autenticación
  const verifyAuthState = useCallback(async () => {
    if (!tokens.accessToken) {
      setLoading(false)
      return
    }

    try {
      const { isValid } = await authService.verifyToken(tokens.accessToken)

      if (!isValid) {
        await handleLogout()
        return
      }

      updateUserFromToken(tokens.accessToken)

      // Verificar si el token está próximo a expirar
      const decoded = jwtDecode(tokens.accessToken)
      const expiresSoon =
        decoded.exp && decoded.exp * 1000 - Date.now() < 5 * 60 * 1000

      if (expiresSoon && tokens.refreshToken) {
        try {
          const newTokens = await authService.refreshToken(tokens.refreshToken)
          storeTokens(newTokens, rememberMe)
          updateUserFromToken(newTokens.accessToken)
        } catch (refreshError) {
          console.error('Error refrescando token:', refreshError)
          await handleLogout()
        }
      }
    } catch (err) {
      console.error('Error verificando autenticación:', err)
      await handleLogout()
    } finally {
      setLoading(false)
    }
  }, [tokens.accessToken, tokens.refreshToken, handleLogout, updateUserFromToken, rememberMe, storeTokens])

  // Verifica el estado de autenticación al cargar
  useEffect(() => {
    let isMounted = true

    const initializeAuth = async () => {
      if (isMounted) {
        await verifyAuthState()
      }
    }

    initializeAuth()

    return () => {
      isMounted = false
    }
  }, [verifyAuthState])

  // Función para manejar la verificación periódica del token
  const checkTokenExpiration = useCallback(() => {
    if (tokens.accessToken) {
      const decoded = jwtDecode(tokens.accessToken)
      const isExpired = decoded.exp && decoded.exp * 1000 < Date.now()

      if (isExpired) {
        handleLogout()
      }
    }
  }, [tokens.accessToken, handleLogout])

  // Configurar verificación periódica del token (cada minuto)
  useEffect(() => {
    const interval = setInterval(checkTokenExpiration, 60 * 1000)
    return () => clearInterval(interval)
  }, [checkTokenExpiration])

  // Configurar interceptores de axios
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (tokens.accessToken) {
          config.headers.Authorization = `Bearer ${tokens.accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        if (
          error.response?.status === 401 &&
          !originalRequest._retry &&
          tokens.refreshToken
        ) {
          originalRequest._retry = true

          try {
            const data = await authService.refreshToken(tokens.refreshToken)
            storeTokens(data, rememberMe)
            updateUserFromToken(data.accessToken)

            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`
            return axios(originalRequest)
          } catch (refreshError) {
            await handleLogout()
            return Promise.reject(refreshError)
          }
        }

        return Promise.reject(error)
      },
    )

    return () => {
      axios.interceptors.request.eject(requestInterceptor)
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [tokens, handleLogout, updateUserFromToken, rememberMe, storeTokens])

  return (
    <AuthContext.Provider
      value={{
        user,
        tokens,
        loading,
        error,
        rememberMe,
        isAuthenticated: !!user && !!tokens.accessToken,
        handleLogin,
        handleLogout,
        setRememberMe: (value) => {
          setRememberMe(value)
          localStorage.setItem('rememberMe', value.toString())
          // Si cambia la preferencia, migrar tokens entre almacenamientos
          if (value) {
            // Mover a localStorage
            localStorage.setItem('accessToken', tokens.accessToken)
            localStorage.setItem('refreshToken', tokens.refreshToken)
            sessionStorage.removeItem('accessToken')
            sessionStorage.removeItem('refreshToken')
          } else {
            // Mover a sessionStorage
            sessionStorage.setItem('accessToken', tokens.accessToken)
            sessionStorage.setItem('refreshToken', tokens.refreshToken)
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
          }
        },
        updateUserFromToken,
        hasRole: (requiredRole) => {
          if (!user?.role) return false
          return user.role === requiredRole
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}