import { Box, Typography } from '@mui/material'

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        bgcolor: 'secondary.light',
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        width: '100%',
      }}
    >
      <Typography variant="body2" color="secondary.main">
        © {new Date().getFullYear()} AgroAdmin - Todos los derechos reservados
      </Typography>
    </Box>
  )
}
