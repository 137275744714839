import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Divider,
} from '@mui/material'
import '../ExploitationsWidget.css'
import { Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

// Configuración básica de dayjs (opcionalmente puedes usar tu archivo de configuración)
import 'dayjs/locale/es'
dayjs.locale('es')

const INITIAL_FORM_STATE = {
  name: '',
  nif: '',
  nationalRegistryNumber: '',
  regionalRegistryNumber: '',
  address: '',
  city: '',
  postalCode: '',
  province: '',
  landline: '',
  mobilePhone: '',
  totalArea: 0,
  startDate: dayjs().format('YYYY-MM-DD'),
}

const ExploitationFormModal = ({
  open,
  onClose,
  exploitation,
  onSave,
  loading,
  isNew,
}) => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE)

  useEffect(() => {
    if (open) {
      setFormData(
        isNew
          ? INITIAL_FORM_STATE
          : {
              ...exploitation,
              startDate:
                exploitation?.startDate || INITIAL_FORM_STATE.startDate,
            },
      )
    }
  }, [open, exploitation, isNew])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      startDate: date ? date.format('YYYY-MM-DD') : null,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSave(formData)
  }

  return React.createElement(
    LocalizationProvider,
    {
      dateAdapter: AdapterDayjs,
    },
    React.createElement(
      Dialog,
      {
        open: open,
        onClose: onClose,
        maxWidth: 'md',
        fullWidth: true,
        PaperProps: { sx: { borderRadius: 2 } },
      },
      [
        React.createElement(
          DialogTitle,
          {
            key: 'title',
            sx: { bgcolor: 'primary.main', color: 'white' },
          },
          React.createElement(
            Typography,
            { variant: 'h6' },
            isNew
              ? 'Nueva Explotación Agrícola'
              : `Editar: ${exploitation?.name || 'Explotación'}`,
          ),
        ),

        React.createElement(
          DialogContent,
          { key: 'content', sx: { py: 3 } },
          React.createElement('form', { onSubmit: handleSubmit }, [
            // Sección de Información Principal
            React.createElement(Box, { key: 'info-section', mb: 4 }, [
              React.createElement(
                Typography,
                {
                  key: 'info-title',
                  variant: 'subtitle1',
                  gutterBottom: true,
                  sx: {
                    fontWeight: 'bold',
                    color: 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                  },
                },
                'Información Principal',
              ),

              React.createElement(Divider, {
                key: 'info-divider',
                sx: { mb: 2 },
              }),

              React.createElement(
                Grid,
                { key: 'info-grid', container: true, spacing: 2 },
                [
                  createTextField(
                    'Nombre de la Explotación',
                    'name',
                    formData.name,
                    handleChange,
                    true,
                    { xs: 12, md: 6 },
                  ),
                  createTextField(
                    'NIF',
                    'nif',
                    formData.nif,
                    handleChange,
                    true,
                    { xs: 12, md: 6 },
                  ),
                  createTextField(
                    'Registro Nacional',
                    'nationalRegistryNumber',
                    formData.nationalRegistryNumber,
                    handleChange,
                    false,
                    { xs: 12, md: 6 },
                  ),
                  createTextField(
                    'Registro Regional',
                    'regionalRegistryNumber',
                    formData.regionalRegistryNumber,
                    handleChange,
                    false,
                    { xs: 12, md: 6 },
                  ),
                ],
              ),
            ]),

            // Sección de Ubicación
            React.createElement(Box, { key: 'location-section', mb: 4 }, [
              React.createElement(
                Typography,
                {
                  key: 'location-title',
                  variant: 'subtitle1',
                  gutterBottom: true,
                  sx: {
                    fontWeight: 'bold',
                    color: 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                  },
                },
                'Ubicación',
              ),

              React.createElement(Divider, {
                key: 'location-divider',
                sx: { mb: 2 },
              }),

              React.createElement(
                Grid,
                { key: 'location-grid', container: true, spacing: 2 },
                [
                  createTextField(
                    'Dirección Completa',
                    'address',
                    formData.address,
                    handleChange,
                    false,
                    { xs: 12 },
                    { multiline: true, rows: 2 },
                  ),
                  createTextField(
                    'Ciudad',
                    'city',
                    formData.city,
                    handleChange,
                    false,
                    { xs: 12, md: 4 },
                  ),
                  createTextField(
                    'Código Postal',
                    'postalCode',
                    formData.postalCode,
                    handleChange,
                    false,
                    { xs: 12, md: 4 },
                  ),
                  createTextField(
                    'Provincia',
                    'province',
                    formData.province,
                    handleChange,
                    false,
                    { xs: 12, md: 4 },
                  ),
                ],
              ),
            ]),

            // Sección de Contacto y Detalles
            React.createElement(Box, { key: 'contact-section', mb: 4 }, [
              React.createElement(
                Typography,
                {
                  key: 'contact-title',
                  variant: 'subtitle1',
                  gutterBottom: true,
                  sx: {
                    fontWeight: 'bold',
                    color: 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                  },
                },
                'Contacto y Detalles',
              ),

              React.createElement(Divider, {
                key: 'contact-divider',
                sx: { mb: 2 },
              }),

              React.createElement(
                Grid,
                { key: 'contact-grid', container: true, spacing: 2 },
                [
                  createTextField(
                    'Teléfono Fijo',
                    'landline',
                    formData.landline,
                    handleChange,
                    false,
                    { xs: 12, md: 6 },
                  ),
                  createTextField(
                    'Teléfono Móvil',
                    'mobilePhone',
                    formData.mobilePhone,
                    handleChange,
                    false,
                    { xs: 12, md: 6 },
                  ),
                  createTextField(
                    'Área Total (hectáreas)',
                    'totalArea',
                    formData.totalArea,
                    handleChange,
                    false,
                    { xs: 12, md: 6 },
                    { type: 'number', inputProps: { step: '0.01' } },
                  ),

                  React.createElement(
                    Grid,
                    { key: 'date-picker', item: true, xs: 12, md: 6 },
                    React.createElement(DatePicker, {
                      label: 'Fecha de Inicio de Actividad',
                      value: formData.startDate
                        ? dayjs(formData.startDate)
                        : null,
                      onChange: handleDateChange,
                      renderInput: (params) =>
                        React.createElement(TextField, {
                          ...params,
                          fullWidth: true,
                          size: 'small',
                          sx: { mb: 2 },
                        }),
                    }),
                  ),
                ],
              ),
            ]),
          ]),
        ),

        React.createElement(
          DialogActions,
          {
            key: 'actions',
            sx: { px: 3, py: 2, borderTop: 1, borderColor: 'divider' },
          },
          [
            React.createElement(
              Button,
              {
                key: 'cancel',
                onClick: onClose,
                startIcon: React.createElement(CancelIcon),
                variant: 'outlined',
                sx: { mr: 2 },
              },
              'Cancelar',
            ),

            React.createElement(
              Button,
              {
                key: 'save',
                onClick: handleSubmit,
                variant: 'contained',
                color: 'primary',
                startIcon: loading
                  ? React.createElement(CircularProgress, {
                      size: 20,
                      color: 'inherit',
                    })
                  : React.createElement(SaveIcon),
                disabled: loading,
              },
              loading ? 'Guardando...' : 'Guardar',
            ),
          ],
        ),
      ],
    ),
  )
}

// Función auxiliar para crear campos TextField
function createTextField(
  label,
  name,
  value,
  onChange,
  required,
  gridProps,
  textFieldProps = {},
) {
  return React.createElement(
    Grid,
    { key: name, item: true, ...gridProps },
    React.createElement(TextField, {
      label: label,
      name: name,
      value: value,
      onChange: onChange,
      fullWidth: true,
      required: required,
      size: 'small',
      sx: { mb: 2 },
      ...textFieldProps,
    }),
  )
}

export default ExploitationFormModal
