import React from 'react';
import { Grid } from '@mui/material';
import UsersWidget from '../features/users/UsersWidget';

export default function UsersPage() {
  return (
    <Grid container>
      <Grid item xs={12} sx={{
        maxWidth: 1200,
        mx: 'auto',
        px: 3,
        width: '100%'
      }}>
        <UsersWidget />
      </Grid>
    </Grid>
  );
}