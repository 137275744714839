import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  MenuItem,
  Typography,
  Divider,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  Box,
  CircularProgress
} from '@mui/material';
import { Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material';
import MapComponent from '../../../components/MapComponent';
import '../ParcelsWidget.css';

// Coordenadas por defecto (Madrid, España)
const DEFAULT_POSITION = [-3.7038, 40.4168];

const INITIAL_PARCEL_STATE = {
  id: null,
  name: '',
  soilType: '',
  coordinates: '',
  geojson: '',
  area: 0,
  flightDate: new Date().toISOString().split('T')[0],
  cadastralMappingDate: '',
  province: '',
  municipality: '',
  aggregate: '',
  zone: '',
  polygon: 0,
  parcelNumber: 0,
  cadastralReference: '',
  slopePercentage: 0,
  altitude: 0,
  landUse: '',
  irrigationCoefficient: 0,
  incidents: '',
  region: '',
  initialPosition: DEFAULT_POSITION
};

const soilTypes = ['Arcilloso', 'Franco', 'Arenoso', 'Limoso', 'Franco-arcilloso'];
const landUses = ['Olivar intensivo', 'Olivar tradicional', 'Cereal', 'Viñedo', 'Frutales'];

const ParcelFormModal = ({ open, onClose, parcel, onSave, loading, isNew }) => {
  const [formData, setFormData] = useState(INITIAL_PARCEL_STATE);
  const [mapKey, setMapKey] = useState(Date.now());
  const [errors, setErrors] = useState({});
  const [mapLoading, setMapLoading] = useState(false);
  const [locationError, setLocationError] = useState(false);

  // Función para obtener la ubicación del usuario
  const getUserLocation = useCallback(() => {
    if (!isNew) return;

    setMapLoading(true);
    setLocationError(false);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { longitude, latitude } = position.coords;
          setFormData(prev => ({
            ...prev,
            initialPosition: [longitude, latitude],
            coordinates: `${latitude},${longitude}`
          }));
          setMapLoading(false);
        },
        (error) => {
          console.error('Error obteniendo ubicación:', error);
          setLocationError(true);
          setMapLoading(false);
        },
        {
          timeout: 10000,
          enableHighAccuracy: true,
          maximumAge: 0
        }
      );
    } else {
      console.warn('Geolocation no soportado por el navegador');
      setLocationError(true);
      setMapLoading(false);
    }
  }, [isNew]);

  useEffect(() => {
    if (open) {
      const initialData = isNew
        ? { ...INITIAL_PARCEL_STATE }
        : {
            ...INITIAL_PARCEL_STATE,
            ...parcel,
            initialPosition: parcel.coordinates
              ? parcel.coordinates.split(',').map(Number).reverse()
              : DEFAULT_POSITION
          };

      setFormData(initialData);
      setErrors({});
      setMapKey(Date.now());

      if (isNew) {
        getUserLocation();
      }
    }
  }, [open, isNew, parcel, getUserLocation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleMapChange = useCallback((geojson) => {
    setFormData(prev => ({
      ...prev,
      geojson,
      coordinates: extractCoordinatesFromGeoJSON(geojson)
    }));
  }, []);

  const extractCoordinatesFromGeoJSON = (geojson) => {
    if (!geojson) return '';
    try {
      const parsed = typeof geojson === 'string' ? JSON.parse(geojson) : geojson;
      const coordinates = parsed.features?.[0]?.geometry?.coordinates?.[0]?.[0];
      if (coordinates) {
        return `${coordinates[1]},${coordinates[0]}`;
      }
    } catch (e) {
      console.error('Error parsing GeoJSON:', e);
    }
    return '';
  };

  const handleRecintoSelected = useCallback((recintoInfo) => {
    setFormData(prev => ({
      ...prev,
      cadastralReference: recintoInfo.recinto || prev.cadastralReference,
      province: recintoInfo.provincia || prev.province,
      municipality: recintoInfo.municipio || prev.municipality,
      landUse: recintoInfo.uso_sigpac || prev.landUse,
      area: recintoInfo.superficie_ha || prev.area,
      incidents: recintoInfo.incidencias || prev.incidents,
      initialPosition: recintoInfo.coordinates
        ? [parseFloat(recintoInfo.coordinates[0]), parseFloat(recintoInfo.coordinates[1])]
        : prev.initialPosition,
      coordinates: recintoInfo.coordinates
        ? `${recintoInfo.coordinates[1]},${recintoInfo.coordinates[0]}`
        : prev.coordinates
    }));
  }, []);

  const showRecintoInfo = useCallback((title, content) => {
    console.log(title, content);
  }, []);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Nombre es requerido';
    if (!formData.cadastralReference.trim()) newErrors.cadastralReference = 'Referencia catastral es requerida';
    if (formData.area <= 0) newErrors.area = 'Área debe ser mayor que 0';
    if (!formData.geojson) newErrors.geojson = 'Debe seleccionar un área en el mapa';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleSubmit = useCallback(() => {
    if (validateForm()) {
      const dataToSave = {
        ...formData,
        coordinates: formData.coordinates || extractCoordinatesFromGeoJSON(formData.geojson)
      };
      onSave(dataToSave);
    }
  }, [formData, onSave, validateForm]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth scroll="paper">
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
        {isNew ? 'Nueva Parcela' : `Editar Parcela: ${formData.name || ''}`}
      </DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent dividers sx={{ pt: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Información Básica
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Nombre de la parcela"
                  value={formData.name || ''}
                  onChange={handleChange}
                  fullWidth
                  required
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="cadastralReference"
                  label="Referencia Catastral"
                  value={formData.cadastralReference || ''}
                  onChange={handleChange}
                  fullWidth
                  required
                  error={!!errors.cadastralReference}
                  helperText={errors.cadastralReference}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="area"
                  label="Área (ha)"
                  type="number"
                  value={formData.area || 0}
                  onChange={handleChange}
                  fullWidth
                  required
                  inputProps={{ min: 0.01, step: 0.01 }}
                  error={!!errors.area}
                  helperText={errors.area}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de suelo</InputLabel>
                  <Select
                    name="soilType"
                    value={formData.soilType || ''}
                    label="Tipo de suelo"
                    onChange={handleChange}
                  >
                    {soilTypes.map(type => (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Uso del suelo</InputLabel>
                  <Select
                    name="landUse"
                    value={formData.landUse || ''}
                    label="Uso del suelo"
                    onChange={handleChange}
                  >
                    {landUses.map(use => (
                      <MenuItem key={use} value={use}>{use}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Ubicación
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="province"
                  label="Provincia"
                  value={formData.province || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="municipality"
                  label="Municipio"
                  value={formData.municipality || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="coordinates"
                  label="Coordenadas"
                  value={formData.coordinates || ''}
                  onChange={handleChange}
                  fullWidth
                  helperText="Formato: lat,long"
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>

              <Box className="map-frame-section">
                <Box className="map-frame-container">
                  <Typography variant="subtitle2" gutterBottom>
                    Mapa de la parcela
                    {errors.geojson && (
                      <Typography variant="caption" color="error" sx={{ ml: 1 }}>
                        {errors.geojson}
                      </Typography>
                    )}
                    {locationError && isNew && (
                      <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                        (Usando ubicación por defecto)
                      </Typography>
                    )}
                  </Typography>
                  <MapComponent
                    key={mapKey}
                    geojson={formData.geojson}
                    initialPosition={formData.initialPosition}
                    onChange={handleMapChange}
                    onRecintoSelected={handleRecintoSelected}
                    showInfoCard={showRecintoInfo}
                    height={350}
                  />
                  {mapLoading && (
                    <Box sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(255,255,255,0.7)',
                      zIndex: 1000
                    }}>
                      <CircularProgress size={24} />
                      <Typography variant="body2" sx={{ ml: 2 }}>
                        Obteniendo tu ubicación...
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Detalles Adicionales
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="slopePercentage"
                  label="Pendiente (%)"
                  type="number"
                  value={formData.slopePercentage || 0}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{ min: 0, max: 100 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="altitude"
                  label="Altitud (m)"
                  type="number"
                  value={formData.altitude || 0}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="irrigationCoefficient"
                  label="Coef. Riego"
                  type="number"
                  step="0.1"
                  value={formData.irrigationCoefficient || 0}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{ min: 0, max: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="region"
                  label="Región"
                  value={formData.region || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="incidents"
                  label="Incidencias"
                  value={formData.incidents || ''}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          startIcon={<CancelIcon />}
          color="secondary"
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ParcelFormModal);