export const adjustLayersBasedOnZoom = (map, baseLayers, zoom) => {
  if (!map) return

  if (zoom >= 17.5) {
    baseLayers.ign.setVisible(false)
    baseLayers.esri.setVisible(true)
    baseLayers.sigpac.setVisible(true)
    baseLayers.osm.setVisible(false)
  } else if (zoom >= 14) {
    baseLayers.ign.setVisible(true)
    baseLayers.esri.setVisible(false)
    baseLayers.sigpac.setVisible(false)
    baseLayers.osm.setVisible(false)
  } else {
    baseLayers.ign.setVisible(false)
    baseLayers.esri.setVisible(false)
    baseLayers.sigpac.setVisible(false)
    baseLayers.osm.setVisible(true)
  }
}
