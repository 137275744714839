export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { longitude, latitude } = position.coords;
          resolve([longitude, latitude]);
        },
        (error) => {
          console.error('Error obteniendo ubicación:', error);
          reject([0, 0]); // Fallback a coordenadas por defecto
        }
      );
    } else {
      console.warn('Geolocation no soportado por el navegador');
      reject([0, 0]); // Fallback a coordenadas por defecto
    }
  });
};