import { Box, Typography, List, ListItem, Button } from '@mui/material'
import { Link } from 'react-router-dom'

export default function HomePage() {
  const features = [
    'Gestión de cultivos integrada',
    'Monitoreo en tiempo real',
    'Alertas personalizadas',
    // Añade más características
  ]

  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: 'auto',
        my: 4,
        pb: 10, // Espacio para el footer
        px: 2,
      }}
    >
      <Typography variant="h2" gutterBottom>
        Descripción del Proyecto
      </Typography>
      <Typography paragraph>
        Texto descriptivo sobre tu aplicación agraria...
      </Typography>

      <Typography variant="h2" gutterBottom sx={{ mt: 4 }}>
        Características Principales
      </Typography>
      <List>
        {features.map((feature, index) => (
          <ListItem key={index}>
            <Typography>{feature}</Typography>
          </ListItem>
        ))}
      </List>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          mt: 4,
        }}
      >
        <Button
          component={Link}
          to="/login"
          variant="contained"
          sx={{
            bgcolor: 'primary.light',
            '&:hover': { bgcolor: 'primary.main' },
          }}
        >
          Iniciar Sesión
        </Button>
      </Box>
    </Box>
  )
}
