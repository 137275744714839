import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { theme } from './theme';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import HomePage from './pages/HomePage';
import UsersPage from './pages/UsersPage';
import ExplotationsPage from './pages/ExplotationsPage';
import ParcelsPage from './pages/ParcelsPage';
import CultivationsPage from './pages/CultivationsPage';
import ProductionsPage from './pages/ProductionsPage';
import IrrigationsPage from './pages/IrrigationsPage';
import ToolsPage from './pages/ToolsPage';
import VehiclesPage from './pages/VehiclesPage';
import TreatmentsPage from './pages/TreatmentsPage';
import DevicesPage from './pages/DevicesPage';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}
          >
            <Header />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                maxWidth: 1200,
                mx: 'auto',
                width: '100%',
                pb: 10,
              }}
            >
              <Routes>

                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />

                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <DashboardPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/users"
                  element={
                    <PrivateRoute>
                      <UsersPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/explotations"
                  element={
                    <PrivateRoute>
                      <ExplotationsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/parcels"
                  element={
                    <PrivateRoute>
                      <ParcelsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/cultivations"
                  element={
                    <PrivateRoute>
                      <CultivationsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/productions"
                  element={
                    <PrivateRoute>
                      <ProductionsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/irrigations"
                  element={
                    <PrivateRoute>
                      <IrrigationsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/tools"
                  element={
                    <PrivateRoute>
                      <ToolsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/vehicles"
                  element={
                    <PrivateRoute>
                      <VehiclesPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/treatments"
                  element={
                    <PrivateRoute>
                      <TreatmentsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/devices"
                  element={
                    <PrivateRoute>
                      <DevicesPage />
                    </PrivateRoute>
                  }
                />

                <Route path="/home" element={<Navigate to="/" />} />

                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Box>
            <Footer />
          </Box>
        </LocalizationProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;