import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import {
  Container,
  Box,
  Typography,
  Snackbar,
  Alert,
  Button,
  CircularProgress,
} from '@mui/material'
import RegisterWidget from '../features/register/RegisterWidget'
import UserAPI from '../services/userAPI'
import logo from '../assets/logo.png'
import '../features/register/RegisterWidget.css'

const RegisterPage = () => {
  const navigate = useNavigate()
  const { isAuthenticated, loading: authLoading } = useContext(AuthContext)
  const [registerError, setRegisterError] = useState(null)
  const [formLoading, setFormLoading] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [registeredEmail, setRegisteredEmail] = useState('')

  // Redirige si ya está autenticado
  useEffect(() => {
    if (!authLoading && isAuthenticated) {
      navigate('/dashboard', { replace: true })
    }
  }, [isAuthenticated, authLoading, navigate])

  // Muestra spinner mientras verifica autenticación
  if (authLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={60} />
      </Box>
    )
  }

  // No renderiza nada si está autenticado (será redirigido)
  if (isAuthenticated) {
    return null
  }

  const handleRegisterSubmit = async (formData) => {
    setFormLoading(true)
    setRegisterError(null)
    setRegisteredEmail(formData.email)

    try {
      // Preparamos los datos para el API
      const userData = {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        fullName: formData.name,
        // Agrega otros campos necesarios según tu API
        role: 'USER', // Rol por defecto
        address: '', // Puedes agregar estos campos al formulario si son requeridos
        city: '',
        postalCode: '',
        province: '',
        phone: '',
      }

      // Llamada al API de registro
      const response = await UserAPI.createUser(userData)

      // Guardamos el email para mostrar en el mensaje de éxito
      setRegisteredEmail(formData.email)
      setRegisterError({
        message: `Registro exitoso. Se ha enviado un email de verificación a ${formData.email}`,
        type: 'success',
      })
      setSnackbarOpen(true)

      // Redirigir a login después de 5 segundos
      setTimeout(() => {
        navigate('/login')
      }, 5000)
    } catch (error) {
      console.error('Error en registro:', error)

      const errorMessage = getFriendlyErrorMessage(error)
      setRegisterError({
        message: errorMessage,
        type: classifyError(error),
      })
      setSnackbarOpen(true)
    } finally {
      setFormLoading(false)
    }
  }

  const classifyError = (error) => {
    if (!error) return 'generic'

    // Usamos el manejador de errores del UserAPI si está disponible
    if (error.details) {
      if (error.details.includes('email')) return 'duplicate'
      if (error.details.includes('password')) return 'weakPassword'
    }

    const apiError = error.message || ''

    if (apiError.includes('ya existe') || apiError.includes('already exists'))
      return 'duplicate'
    if (apiError.includes('débil') || apiError.includes('weak'))
      return 'weakPassword'
    if (error.status === 400) return 'validation'
    return 'generic'
  }

  const getFriendlyErrorMessage = (error) => {
    if (!error) return 'Error desconocido al registrar'

    // Usamos el mensaje de error formateado por UserAPI.handleError
    let errorMessage = error.message || 'Error al registrar'

    // Mapeo de mensajes específicos
    const errorMap = {
      'Email ya existe': 'Este email ya está registrado',
      'Usuario ya existe': 'Este nombre de usuario ya está en uso',
      'Contraseña muy débil':
        'La contraseña no cumple con los requisitos de seguridad',
      invalid_email: 'El email proporcionado no es válido',
      'Network Error': 'Error de conexión con el servidor',
      'Failed to fetch': 'Error de conexión con el servidor',
      'El servidor no respondió':
        'El servidor no está respondiendo. Intente más tarde.',
    }

    // Buscar coincidencia exacta o parcial
    for (const [key, value] of Object.entries(errorMap)) {
      if (errorMessage.includes(key)) {
        return value
      }
    }

    // Mensajes por código de estado
    if (error.status === 400) {
      return 'Datos de registro inválidos. Por favor verifica la información.'
    }

    if (error.status === 409) {
      return 'El usuario o email ya existe.'
    }

    return errorMessage
  }

  const handleResendVerification = async () => {
    try {
      // Aquí iría la llamada a tu API para reenviar el email
      // await authService.resendVerificationEmail(registeredEmail);

      setRegisterError({
        message:
          'Email de verificación reenviado. Revise su bandeja de entrada.',
        type: 'success',
      })
      setSnackbarOpen(true)
    } catch (error) {
      setRegisterError({
        message: 'Error al reenviar el email. Intente más tarde.',
        type: 'error',
      })
      setSnackbarOpen(true)
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const renderSnackbarAction = () => {
    if (!registerError?.type) return null

    const actions = {
      success: (
        <Button
          color="secondary"
          size="small"
          onClick={handleResendVerification}
          data-testid="resend-email-button"
        >
          Reenviar email
        </Button>
      ),
      duplicate: (
        <Button
          color="secondary"
          size="small"
          component={Link}
          to="/login"
          data-testid="login-button"
        >
          Iniciar sesión
        </Button>
      ),
    }

    return actions[registerError.type] || null
  }

  return (
    <Container
      maxWidth="sm"
      className="register-page-container"
      data-testid="register-page"
    >
      <Box className="register-page-inner-container">
        <RegisterWidget
          onRegister={handleRegisterSubmit}
          loading={formLoading}
          initialError={registerError?.message}
          customHeader={
            <Box className="register-widget-header">
              <img
                src={logo}
                alt="Logo de la aplicación"
                className="register-page-logo"
                data-testid="register-logo"
              />
              <Typography
                variant="h4"
                className="register-page-title"
                data-testid="register-title"
              >
                Crear Cuenta
              </Typography>
              <Typography
                variant="body1"
                className="register-page-subtitle"
                data-testid="register-subtitle"
              >
                Completa el formulario para registrarte
              </Typography>
            </Box>
          }
          customFooter={
            <Box className="register-widget-footer">
              <Typography variant="body2">
                ¿Ya tienes cuenta?{' '}
                <Link
                  to="/login"
                  className="register-page-legal-link"
                  data-testid="login-link"
                >
                  Inicia sesión
                </Link>
              </Typography>
            </Box>
          }
        />

        <Box className="register-page-legal">
          <Typography variant="caption">
            Al registrarte aceptas nuestros{' '}
            <Link
              to="/terminos"
              className="register-page-legal-link"
              data-testid="terms-link"
            >
              Términos
            </Link>{' '}
            y{' '}
            <Link
              to="/privacidad"
              className="register-page-legal-link"
              data-testid="privacy-link"
            >
              Privacidad
            </Link>
          </Typography>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={registerError?.type === 'success' ? 10000 : 6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          data-testid="register-snackbar"
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={registerError?.type === 'success' ? 'success' : 'error'}
            action={renderSnackbarAction()}
            sx={{ width: '100%', alignItems: 'center' }}
            data-testid={`alert-${registerError?.type || 'error'}`}
          >
            {registerError?.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  )
}

export default RegisterPage
