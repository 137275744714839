// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-frame-section {
  width: 100%;
  padding: 16px 0;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-frame {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/features/parcels/ParcelsWidget.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".map-frame-section {\r\n  width: 100%;\r\n  padding: 16px 0;\r\n}\r\n\r\n.map-container {\r\n  position: relative;\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.map-frame {\r\n  width: 100%;\r\n  height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
