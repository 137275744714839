// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Importa la fuente Poppins */

/* Asegura que el footer no cubra contenido */
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
}

/* Ajuste para el contenido principal */
main {
  padding-bottom: 80px; /* Altura aproximada del footer */
}
`, "",{"version":3,"sources":["webpack://./src/css/Index.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAG9B,6CAA6C;AAC7C;EACE,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA,uCAAuC;AACvC;EACE,oBAAoB,EAAE,iCAAiC;AACzD","sourcesContent":["/* Importa la fuente Poppins */\n@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');\n\n/* Asegura que el footer no cubra contenido */\nbody {\n  margin: 0;\n  padding: 0;\n  min-height: 100vh;\n  position: relative;\n}\n\n/* Ajuste para el contenido principal */\nmain {\n  padding-bottom: 80px; /* Altura aproximada del footer */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
