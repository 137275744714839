// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilos para el contenedor principal */
.login-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 2rem 0;
  width: 100%;
}

/* Estilos para el contenedor interno */
.login-page-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Estilos para el logo */
.login-page-logo {
  height: 70px;
  margin-bottom: 1rem;
}

/* Estilos para móvil */
@media (max-width: 600px) {
  .login-page-logo {
    height: 50px;
  }
}

/* Estilos para los títulos */
.login-page-title {
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.login-page-subtitle {
  color: var(--text-secondary);
}

/* Estilos para los links legales */
.login-page-legal {
  margin-top: 2rem;
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.75rem;
}

.login-page-legal-link {
  color: var(--text-secondary);
  text-decoration: underline;
}

.login-page-legal-link:hover {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/css/LoginPage.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb;;AAEA,uCAAuC;AACvC;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA,yBAAyB;AACzB;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA,uBAAuB;AACvB;EACE;IACE,YAAY;EACd;AACF;;AAEA,6BAA6B;AAC7B;EACE,2BAA2B;EAC3B,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA,mCAAmC;AACnC;EACE,gBAAgB;EAChB,kBAAkB;EAClB,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["/* Estilos para el contenedor principal */\r\n.login-page-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  min-height: 100vh;\r\n  padding: 2rem 0;\r\n  width: 100%;\r\n}\r\n\r\n/* Estilos para el contenedor interno */\r\n.login-page-inner-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  width: 100%;\r\n}\r\n\r\n/* Estilos para el logo */\r\n.login-page-logo {\r\n  height: 70px;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n/* Estilos para móvil */\r\n@media (max-width: 600px) {\r\n  .login-page-logo {\r\n    height: 50px;\r\n  }\r\n}\r\n\r\n/* Estilos para los títulos */\r\n.login-page-title {\r\n  color: var(--primary-color);\r\n  font-weight: 600;\r\n  margin-bottom: 0.5rem;\r\n}\r\n\r\n.login-page-subtitle {\r\n  color: var(--text-secondary);\r\n}\r\n\r\n/* Estilos para los links legales */\r\n.login-page-legal {\r\n  margin-top: 2rem;\r\n  text-align: center;\r\n  color: var(--text-secondary);\r\n  font-size: 0.75rem;\r\n}\r\n\r\n.login-page-legal-link {\r\n  color: var(--text-secondary);\r\n  text-decoration: underline;\r\n}\r\n\r\n.login-page-legal-link:hover {\r\n  text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
