import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  useTheme,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  Lock as LockIcon,
  Person as PersonIcon,
  Email as EmailIcon,
  Badge as BadgeIcon
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import './RegisterWidget.css';

const RegisterWidget = ({
  onRegister,
  loading = false,
  initialError = null,
  elevation = 3,
  sx = {},
  customHeader,
  customFooter
}) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false
  });
  const [error, setError] = useState(initialError);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    name: false,
    email: false,
    username: false,
    password: false,
    confirmPassword: false,
    acceptTerms: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Resetear errores
    setError(null);
    setFieldErrors({
      name: false,
      email: false,
      username: false,
      password: false,
      confirmPassword: false,
      acceptTerms: false
    });

    // Validación de campos
    let hasError = false;
    const newFieldErrors = { ...fieldErrors };

    if (!formData.name.trim()) {
      newFieldErrors.name = true;
      hasError = true;
    }

    if (!formData.email.trim() || !/^\S+@\S+\.\S+$/.test(formData.email)) {
      newFieldErrors.email = true;
      hasError = true;
    }

    if (!formData.username.trim()) {
      newFieldErrors.username = true;
      hasError = true;
    }

    if (!formData.password.trim() || formData.password.length < 8) {
      newFieldErrors.password = true;
      hasError = true;
    }

    if (formData.password !== formData.confirmPassword) {
      newFieldErrors.confirmPassword = true;
      hasError = true;
    }

    if (!formData.acceptTerms) {
      newFieldErrors.acceptTerms = true;
      hasError = true;
    }

    if (hasError) {
      setFieldErrors(newFieldErrors);
      setError('Por favor completa todos los campos correctamente');
      return;
    }

    try {
      await onRegister({
        name: formData.name,
        email: formData.email,
        username: formData.username,
        password: formData.password
      });
    } catch (err) {
      // El error real se maneja en RegisterPage
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'acceptTerms' ? checked : value
    });
    setFieldErrors({
      ...fieldErrors,
      [name]: false
    });
  };

  const handleTogglePassword = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <Paper
      className="register-widget-container"
      elevation={elevation}
      sx={{
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '12px',
        padding: theme.spacing(4),
        width: '100%',
        maxWidth: '450px',
        ...sx
      }}
    >
      {customHeader}

      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ width: '100%' }}
      >
        <TextField
          label="Nombre completo"
          name="name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.name}
          onChange={handleChange}
          error={fieldErrors.name}
          helperText={fieldErrors.name ? 'Ingresa tu nombre completo' : ''}
          disabled={loading}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BadgeIcon sx={{ color: fieldErrors.name ? theme.palette.error.main : theme.palette.text.secondary }} />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Email"
          name="email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.email}
          onChange={handleChange}
          error={fieldErrors.email}
          helperText={fieldErrors.email ? 'Ingresa un email válido' : ''}
          disabled={loading}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon sx={{ color: fieldErrors.email ? theme.palette.error.main : theme.palette.text.secondary }} />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Nombre de usuario"
          name="username"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.username}
          onChange={handleChange}
          error={fieldErrors.username}
          helperText={fieldErrors.username ? 'Ingresa un nombre de usuario' : ''}
          disabled={loading}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon sx={{ color: fieldErrors.username ? theme.palette.error.main : theme.palette.text.secondary }} />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Contraseña"
          name="password"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.password}
          onChange={handleChange}
          error={fieldErrors.password}
          helperText={fieldErrors.password ? 'La contraseña debe tener al menos 8 caracteres' : ''}
          disabled={loading}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon sx={{ color: fieldErrors.password ? theme.palette.error.main : theme.palette.text.secondary }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleTogglePassword('password')}
                  edge="end"
                  size="small"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Confirmar contraseña"
          name="confirmPassword"
          type={showConfirmPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.confirmPassword}
          onChange={handleChange}
          error={fieldErrors.confirmPassword}
          helperText={fieldErrors.confirmPassword ? 'Las contraseñas no coinciden' : ''}
          disabled={loading}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon sx={{ color: fieldErrors.confirmPassword ? theme.palette.error.main : theme.palette.text.secondary }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={() => handleTogglePassword('confirmPassword')}
                  edge="end"
                  size="small"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              name="acceptTerms"
              checked={formData.acceptTerms}
              onChange={handleChange}
              color="primary"
              size="small"
            />
          }
          label={
            <Typography variant="body2" sx={{ color: fieldErrors.acceptTerms ? theme.palette.error.main : theme.palette.text.secondary }}>
              Acepto los términos y condiciones de uso
            </Typography>
          }
          sx={{ mb: 3 }}
        />

        {error && (
          <Alert
            severity="error"
            sx={{
              mb: 2,
              borderRadius: '8px',
              alignItems: 'center'
            }}
          >
            {error}
          </Alert>
        )}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          sx={{
            py: 1.5,
            mb: 2,
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 600,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            }
          }}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
        >
          {loading ? 'Registrando...' : 'Registrarme'}
        </Button>

        {customFooter}
      </Box>
    </Paper>
  );
};

RegisterWidget.propTypes = {
  onRegister: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  initialError: PropTypes.string,
  elevation: PropTypes.number,
  sx: PropTypes.object,
  customHeader: PropTypes.node,
  customFooter: PropTypes.node
};

RegisterWidget.defaultProps = {
  loading: false,
  initialError: null,
  elevation: 1
};

export default RegisterWidget;