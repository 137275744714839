import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  TextField,
  CircularProgress
} from '@mui/material';
import { People as PeopleIcon, Add as AddIcon } from '@mui/icons-material';
import UserTable from './components/UserTable';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import UserAPI from '../../services/userAPI';

// Carga diferida de los modales
const UserFormModal = lazy(() => import('./components/UserFormModal'));
const UserRegisterModal = lazy(() => import('./components/UserRegisterModal'));

const INITIAL_USER_STATE = {
  username: '',
  fullName: '',
  email: '',
  nif: '',
  address: '',
  city: '',
  postalCode: '',
  province: '',
  representationType: '',
  phone: '',
  role: 'USER',
  imageUrl: '',
  avatarUrl: ''
};

const UsersWidget = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(INITIAL_USER_STATE);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('username');
  const [searchTerm, setSearchTerm] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [avatarCache, setAvatarCache] = useState({});

  // Cargar usuarios y avatares
  const fetchUsersAndAvatars = useCallback(async () => {
    try {
      setLoading(true);
      const data = await UserAPI.fetchAllUsers();
      setUsers(data);

      // Precargar avatares
      const newAvatarCache = {};
      for (const user of data) {
        try {
          const avatarUrl = await UserAPI.fetchUserAvatar(user.username);
          newAvatarCache[user.username] = avatarUrl;
        } catch (err) {
          console.error(`Error loading avatar for ${user.username}:`, err);
          newAvatarCache[user.username] = '/default-avatar.png';
        }
      }
      setAvatarCache(newAvatarCache);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsersAndAvatars();
  }, [fetchUsersAndAvatars]);

  // Actualizar avatar después de cambios
  const updateAvatarCache = async (username) => {
    try {
      const avatarUrl = await UserAPI.fetchUserAvatar(username);
      setAvatarCache(prev => ({ ...prev, [username]: avatarUrl }));
    } catch (err) {
      console.error(`Error updating avatar for ${username}:`, err);
      setAvatarCache(prev => ({ ...prev, [username]: '/default-avatar.png' }));
    }
  };

  // Funciones de manejo de la tabla
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPage(0);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  // Funciones de manejo de usuarios
  const handleNewUser = () => {
    setCurrentUser(INITIAL_USER_STATE);
    setEditModalOpen(true);
    setError(null);
  };

  const handleEditOpen = (user) => {
    setCurrentUser(user);
    setEditModalOpen(true);
    setError(null);
  };

  const handleDeleteClick = (user) => {
    setCurrentUser(user);
    setDeleteModalOpen(true);
    setError(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      await UserAPI.deleteUser(currentUser.username);
      await fetchUsersAndAvatars();
      setDeleteModalOpen(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (username, userData) => {
    try {
      setLoading(true);
      await UserAPI.updateUser(username, userData);
      await fetchUsersAndAvatars();
      setEditModalOpen(false);
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const createUser = async (userData) => {
    try {
      setLoading(true);
      await UserAPI.createUser(userData);
      await fetchUsersAndAvatars();
      setEditModalOpen(false);
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const uploadImage = async (username, file, type) => {
    try {
      const result = type === 'avatar'
        ? await UserAPI.uploadUserAvatar(username, file)
        : await UserAPI.uploadUserImage(username, file);

      if (type === 'avatar') {
        await updateAvatarCache(username);
      }
      return result;
    } catch (err) {
      setError(`Error al subir ${type === 'avatar' ? 'el avatar' : 'la imagen'}: ${err.message}`);
      throw err;
    }
  };

  const removeImage = async (username, type) => {
    try {
      const result = type === 'avatar'
        ? await UserAPI.deleteUserAvatar(username)
        : await UserAPI.deleteUserImage(username);

      if (type === 'avatar') {
        setAvatarCache(prev => ({ ...prev, [username]: '/default-avatar.png' }));
      }
      return result;
    } catch (err) {
      setError(`Error al eliminar ${type === 'avatar' ? 'el avatar' : 'la imagen'}: ${err.message}`);
      throw err;
    }
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    setCurrentUser(INITIAL_USER_STATE);
  };

  // Filtrado y ordenación
  const filteredUsers = users.filter(user =>
    user.username.toLowerCase().includes(searchTerm) ||
    (user.fullName?.toLowerCase() || '').includes(searchTerm) ||
    user.email?.toLowerCase().includes(searchTerm)
  );

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    const aValue = a[orderBy] || '';
    const bValue = b[orderBy] || '';
    return order === 'asc'
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });

  const paginatedUsers = sortedUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  ).map(user => ({
    ...user,
    avatarUrl: avatarCache[user.username] || '/default-avatar.png'
  }));

  return (
    <Card sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '14px',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)'
    }}>
      <CardContent sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        '&:last-child': { pb: 3 }
      }}>
        {/* Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
          pb={2}
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <Typography
            variant="h5"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 600,
              color: 'primary.main'
            }}
          >
            <PeopleIcon sx={{ mr: 1.5, fontSize: '1.75rem' }} />
            Gestión de Usuarios
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNewUser}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              px: 2,
              py: 1,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'primary.dark'
              }
            }}
          >
            Nuevo Usuario
          </Button>
        </Box>

        {/* Error message */}
        {error && (
          <Typography
            color="error"
            sx={{
              mb: 2,
              backgroundColor: 'error.light',
              p: 1.5,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {error}
          </Typography>
        )}

        {/* Search field */}
        <TextField
          label="Buscar usuarios"
          variant="outlined"
          size="small"
          fullWidth
          sx={{
            mb: 3,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: 'divider'
              },
              '&:hover fieldset': {
                borderColor: 'primary.light'
              }
            }
          }}
          value={searchTerm}
          onChange={handleSearch}
        />

        {/* Table container */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
              <CircularProgress />
            </Box>
          ) : (
            <UserTable
              users={paginatedUsers}
              loading={loading}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              totalUsers={filteredUsers.length}
              onSort={handleSort}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onEdit={handleEditOpen}
              onDelete={handleDeleteClick}
            />
          )}
        </Box>

        {/* Modals */}
        {editModalOpen && (
          <Suspense fallback={<div>Cargando formulario...</div>}>
            {currentUser.username ? (
              <UserFormModal
                open={editModalOpen}
                onClose={handleCloseModal}
                user={currentUser}
                onSave={updateUser}
                onImageUpload={uploadImage}
                onImageRemove={removeImage}
                loading={loading}
                isNew={false}
              />
            ) : (
              <UserRegisterModal
                open={editModalOpen}
                onClose={handleCloseModal}
                onSave={createUser}
                loading={loading}
              />
            )}
          </Suspense>
        )}

        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirm}
          itemName={currentUser?.username}
          loading={loading}
        />
      </CardContent>
    </Card>
  );
};

export default UsersWidget;